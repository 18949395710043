import { Form, Formik } from "formik";
import { FormikMorseField } from "../../app-container/components/FormikMorseField";
import { Label, Select, Button, SelectOption, Input } from "morse-react";
import { Employee, EmployeeWithLastAppraisedQuarter } from "types";
import { useEffect, useState } from "react";
import { GettingReady } from "../../app-container/components/suspense/GettingReady";
import { useHistory, RouteProps } from "react-router-dom";
import * as Yup from "yup";
import { ApiClient } from "../../api/ApiClient";
import { Dialog } from "morse-react-dialogs";

type StartAppraisalProps = RouteProps & {
    onSaved: () => void;
};

type EmployeeOptions = {
    item: EmployeeWithLastAppraisedQuarter;
    label: string;
    value: number;
};

type AppraiserOptions = Omit<EmployeeOptions, "item"> & {
    item: Employee;
};

export const StartAppraisal = (props: StartAppraisalProps) => {
    const [employees, setEmployees] = useState<EmployeeWithLastAppraisedQuarter[]>([]);
    const [appraisers, setAppraisers] = useState<Employee[]>([]);
    const history = useHistory();

    useEffect(() => {
        ApiClient.employees.list({}).then((list) => setEmployees(list.items));
        ApiClient.employees.listAppraisers().then((list) => setAppraisers(list));
    }, []);

    if (employees.length === 0 || appraisers.length === 0) {
        return <GettingReady />;
    }

    const appraiserOptions: AppraiserOptions[] = appraisers.map((employee) => ({
        item: employee,
        label: employee.firstName + " " + employee.lastName,
        value: employee.id,
    }));

    const employeeOptions: EmployeeOptions[] = employees.map((employee) => ({
        item: employee,
        label: employee.firstName + " " + employee.lastName,
        value: employee.id,
    }));

    const quarters: string[] = [];
    // Get 1 quarter behind and 2 forwards
    for (let x = -1; x < 2; x++) {
        const today = new Date();
        today.setMonth(today.getMonth() + x * 3);
        let quarter = Math.floor((today.getMonth() + 3) / 3);
        //sometimes when it is quarter 4 it returns 0
        if (quarter === 0) {
            quarter = 4;
        }
        quarters.push(today.getFullYear() + "Q" + quarter);
    }

    const defaultPeerIds: number[] = [];

    const filterOptions = (
        optionList: (EmployeeOptions | AppraiserOptions)[],
        option: SelectOption<Employee, number>,
        search: string
    ) => {
        search = search.toLowerCase();
        const employee = optionList.find((e) => e.value === option.value);

        if (employee) {
            return employee.label.toLowerCase().includes(search);
        } else {
            return false;
        }
    };

    return (
        <Formik
            initialValues={{
                employeeId: 0,
                //If only one appraiser in the list then select them by default
                appraiserId: appraiserOptions.length === 1 ? appraiserOptions[0].value : 0,
                peerIds: defaultPeerIds,
                quarterYear: "",
            }}
            onSubmit={async (values, { setErrors }) => {
                try {
                    await ApiClient.appraisals.createAppraisal(values);
                    history.push("/appraisals");
                    props.onSaved();
                } catch (e) {
                    if (e.response?.data?.errors) {
                        setErrors(e.response.data.errors);
                    }
                }
            }}
            validationSchema={Yup.object().shape({
                employeeId: Yup.number().not([0], "You must select an employee"),
                appraiserId: Yup.number().not([0], "You must select an appraiser"),
                peerIds: Yup.array().min(1, "You must select at least 1 peer"),
                quarterYear: Yup.string().required("You must select a quarter"),
            })}
        >
            {({ handleSubmit, isSubmitting, values, errors, setValues, touched }) => {
                const employeesToShow = employeeOptions.filter((employee) => {
                    return (
                        employee.item.id !== values.appraiserId &&
                        !values.peerIds.includes(employee.item.id)
                    );
                });

                const appraisersToShow = appraiserOptions.filter((appraiser) => {
                    return (
                        appraiser.item.id !== values.employeeId &&
                        !values.peerIds.includes(appraiser.item.id)
                    );
                });

                const peersToShow = employeeOptions.filter((employee) => {
                    return (
                        employee.item.id !== values.employeeId &&
                        employee.item.id !== values.appraiserId
                    );
                });

                const quartersToShow = quarters.filter((quarter) => {
                    const employee = employeeOptions.find((e) => e.item.id === values.employeeId);
                    if (employee) {
                        const lastAppraisedQuarter = employee.item.lastAppraisedQuarter;
                        if (lastAppraisedQuarter) {
                            //Split quarter strings into array [year, quarter]
                            const lastYearAndQuarter = lastAppraisedQuarter
                                .split("Q")
                                .map((val) => Number(val));
                            const yearAndQuarter = quarter.split("Q").map((val) => Number(val));
                            return (
                                //Don't show last appraised quarter or any preceding quarters
                                quarter !== lastAppraisedQuarter &&
                                yearAndQuarter[0] >= lastYearAndQuarter[0] &&
                                yearAndQuarter[1] > lastYearAndQuarter[1]
                            );
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });

                return (
                    <Dialog
                        allowDismiss
                        title="Create Appraisal"
                        onDismissed={() => {
                            history.push("/appraisals");
                        }}
                        buttons={[
                            <Button
                                key="submit"
                                busy={isSubmitting}
                                disabled={isSubmitting}
                                onClick={() => {
                                    return handleSubmit();
                                }}
                            >
                                Start Appraisal
                            </Button>,
                        ]}
                    >
                        <Form>
                            <FormikMorseField field="employeeId">
                                <Label>Employee</Label>
                                <Select<Employee, number>
                                    fill
                                    searchable
                                    clearable
                                    value={values.employeeId}
                                    options={employeesToShow}
                                    filterInClient
                                    filterPredicate={(option, search) => {
                                        return filterOptions(
                                            employeesToShow,
                                            option as SelectOption<Employee, number>,
                                            search
                                        );
                                    }}
                                    onChange={(value) => {
                                        setValues({
                                            ...values,
                                            employeeId: value ?? 0,
                                            quarterYear: "", //Reset quarter selection when a new employee is selected
                                        });
                                    }}
                                />
                            </FormikMorseField>

                            <FormikMorseField field="appraiserId">
                                <Label>Appraiser</Label>
                                {appraiserOptions.length > 1 ? (
                                    <Select<Employee, number>
                                        fill
                                        options={appraisersToShow}
                                        filterInClient
                                        filterPredicate={(option, search) => {
                                            return filterOptions(
                                                appraisersToShow,
                                                option as SelectOption<Employee, number>,
                                                search
                                            );
                                        }}
                                        value={values.appraiserId}
                                        onChange={(value) => {
                                            setValues({ ...values, appraiserId: value ?? 0 });
                                        }}
                                    />
                                ) : (
                                    //Don't show drop down if only one appraiser option
                                    <Input
                                        fill
                                        disabled
                                        type="text"
                                        value={appraiserOptions[0].label}
                                    />
                                )}
                            </FormikMorseField>

                            <FormikMorseField field="peerIds">
                                <Label>Peers</Label>
                                <Select<Employee, number, true>
                                    fill
                                    multiple
                                    searchable
                                    clearable
                                    options={peersToShow}
                                    filterInClient
                                    filterPredicate={(option, search) => {
                                        return filterOptions(
                                            peersToShow,
                                            option as SelectOption<Employee, number>,
                                            search
                                        );
                                    }}
                                    value={values.peerIds}
                                    onChange={(value) => {
                                        setValues({ ...values, peerIds: value ?? [] });
                                    }}
                                />
                            </FormikMorseField>

                            <FormikMorseField field="quarterYear">
                                <Label>Quarter</Label>
                                <Select<string, string>
                                    fill
                                    clearable
                                    options={quartersToShow}
                                    value={values.quarterYear}
                                    onChange={(value) => {
                                        setValues({ ...values, quarterYear: value ?? "" });
                                    }}
                                />
                            </FormikMorseField>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
};
