import { PropsWithChildren, useContext } from "react";
import { AuthenticationContext } from "@gcdtech/acorn-react-core";

export const IdentityGuard = (props: PropsWithChildren<Record<string, unknown>>) => {
    const context = useContext(AuthenticationContext);

    if (context.fetchingIdentity) {
        return <div className="c-alert">Authenticating... Please wait</div>;
    } else if (!context.identity) {
        return (
            <div className="c-alert u-fill-warn">
                Hello, your performance record is not yet ready. Please contact HR to find out more.
            </div>
        );
    }

    return <>{props.children}</>;
};
