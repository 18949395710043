import dayjs from "dayjs";
import { FastField } from "formik";
import { InputHTMLAttributes } from "react";
import { FormikInputProps } from "@gcdtech/acorn-react-core";

const DayJsCompatibleDateInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
    const { value, ...rest } = props;

    // Note the check on the first character.
    // If the value starts with a four digit year but has zero in the character, dayjs will
    // construct this as a 2 digit year resulting in a 1900 date!
    //
    // See conversation here for examples:
    // https://github.com/iamkun/dayjs/issues/1237

    const date =
        value && value.toString().length > 0 && value.toString()[0] !== "0"
            ? dayjs(value.toString()).format("YYYY-MM-DD")
            : value;

    return <input type="date" value={date} {...rest} />;
};

export const FormikDate = (props: Omit<FormikInputProps, "type">) => {
    const { name, ...propBag } = props;

    return (
        <FastField
            name={name}
            as={DayJsCompatibleDateInput}
            validate={props.validate}
            {...propBag}
        />
    );
};
