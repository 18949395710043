import { Link } from "react-router-dom";
import { useState } from "react";
import { EmployeeDialog, FormValues } from "../EmployeeDialog";
import { ApiClient } from "../../../api/ApiClient";
import { CrudContainer } from "@gcdtech/acorn-crud-react";
import { EmployeeList } from "./EmployeeList";
import { Employee } from "../../../../../types/build";
import * as Yup from "yup";
import { ScreenMode } from "@gcdtech/acorn-react-core";

export const StaffList = () => {
    useState<FormValues>({
        firstName: "",
        lastName: "",
        email: "",
        roleId: 0,
        gradeId: 0,
        level: 1,
        lastPromoted: new Date(),
        userRole: "",
    });

    return (
        <>
            <div className="cc-panel +block">
                <h2 className="cc-panel__title c-heading +h2">Staff List</h2>
                <div className="cc-panel__buttons">
                    <Link to="/appraisals/archived-employees" className="c-button +quiet">
                        View archived employees
                    </Link>
                    <Link to="/appraisals/employees/create" className="c-button">
                        Add a new staff member
                    </Link>
                </div>
                <div className="cc-panel__body">
                    <CrudContainer<Employee>
                        api={ApiClient.employees}
                        path="/appraisals/employees"
                        crudSettings={{
                            itemName: "Employee",
                        }}
                        screenMode={ScreenMode.Dialog}
                        listComponent={EmployeeList}
                        createItemComponent={EmployeeDialog}
                        updateItemComponent={EmployeeDialog}
                        validationSchema={Yup.object().shape({
                            firstName: Yup.string().required("You must enter a first name"),
                            lastName: Yup.string().required("You must enter a last name"),
                            email: Yup.string().required("You must enter an email"),
                            roleId: Yup.number().required("You must select a role"),
                            gradeId: Yup.number().required("You must select a grade"),
                            level: Yup.number()
                                .min(1, "Level must be at least 1")
                                .required("You must enter a level"),
                        })}
                    />
                </div>
            </div>
        </>
    );
};
