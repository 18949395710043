import { GuardedContainer, GuardedRoute } from "@gcdtech/acorn-react-core";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Appraisal, ApprovalStatus, DirectorPermissions, Rating, AppraisalRelation } from "types";
import { Button, Form, Label } from "morse-react";
import { ApiClient } from "../../api/ApiClient";
import { TruthPill } from "../../app-container/components/TruthPill";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormikMorseField } from "../../app-container/components/FormikMorseField";
import { RoleChangeDialog } from "./components/RoleChangeDialog";
import { QuillEditor } from "../appraisals/QuillEditor";
import "../../App.css";
import { useMemo, useState } from "react";
import { RatingForm } from "./components/RatingForm";
import { FractionPeerFeebackComplete } from "./components/FractionPeerFeedbackComplete";
import dayjs from "dayjs";

export const AppraisalsReview = (props: { appraisal: Appraisal }) => {
    const [reload, setReload] = useState<number>(0);
    const { appraisal } = props;
    const history = useHistory();

    const DisplayPeerFeedBack = () => {
        const peers = appraisal?.peers.map((peer) => {
            if (appraisal.peerFeedbackComplete) {
                return (
                    <li key={peer.name}>
                        <span className="cc-peer__avatar" />
                        <span className="cc-peer__name-group">
                            <span className="cc-peer__name">{peer.name}</span>
                        </span>
                    </li>
                );
            } else {
                return (
                    <li key={peer.name}>
                        <span className="cc-peer__avatar" />
                        <span className="cc-peer__name-group">
                            <span className="cc-peer__name">{peer.name}</span>
                            {/*// @ts-ignore */}
                            <TruthPill value={peer.submitted} />
                        </span>
                    </li>
                );
            }
        });
        if (
            appraisal?.approvalStatus === ApprovalStatus.Approved ||
            appraisal?.relationship !== AppraisalRelation.OwnAppraisal
        ) {
            if (appraisal?.peerFeedBack) {
                const praise = appraisal.peerFeedBack.praise.map((praise) => {
                    const praises = praise.split("\n");
                    return (
                        <>
                            {praises.map(
                                (praise, index) =>
                                    praise.trim() !== "" && (
                                        <li
                                            className="cc-panel cc-appraisal__feedback +1b +x-tight"
                                            key={`praise-${index}`}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={dangerousHTMLMarkup(
                                                    praise
                                                )}
                                            />
                                        </li>
                                    )
                            )}
                        </>
                    );
                });

                const critique = appraisal.peerFeedBack.criique.map((critique) => {
                    const critiques = critique.split("\n");
                    return (
                        <>
                            {critiques.map(
                                (critique, index) =>
                                    critique.trim() !== "" && (
                                        <li
                                            className="cc-panel cc-appraisal__feedback +1b +x-tight"
                                            key={`critique-${index}`}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={dangerousHTMLMarkup(
                                                    critique
                                                )}
                                            />
                                        </li>
                                    )
                            )}
                        </>
                    );
                });

                return (
                    <>
                        <div className="cc-panel">
                            <h2 className="c-heading +h2">Peers</h2>
                            <ul className="cc-peers">{peers}</ul>
                        </div>
                        <div className="cc-panel">
                            <h2 className="c-heading +h2">Peer Feedback</h2>
                            <div className="cc-panel +2 +tighter">
                                <h3 className="c-heading +h3">Praise</h3>
                                <ul>{praise}</ul>
                            </div>
                            <div className="cc-panel +2 +tighter">
                                <h3 className="c-heading +h3">Opportunity for Growth</h3>
                                <ul>{critique}</ul>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <div className="cc-panel">
                            <h2 className="c-heading +h2">Peers</h2>
                            <ul className="cc-peers">{peers}</ul>
                        </div>
                        <div className="cc-panel">
                            <h2 className="c-heading +h2">Peer Feedback</h2>
                            <div className="cc-panel +2 +x-tight">
                                There is no peer feedback yet
                            </div>
                        </div>
                    </>
                );
            }
        } else {
            return (
                <div className="cc-panel">
                    <h2 className="c-heading +h2">Peers</h2>
                    <ul className="cc-peers">{peers}</ul>
                    <FractionPeerFeebackComplete
                        peerFeedbackComplete={appraisal.peerFeedbackComplete}
                        numberComplete={appraisal.numberPeerFeedBackComplete}
                        totalPeers={appraisal.peers.length}
                    />
                </div>
            );
        }
    };

    const initialData = useMemo(() => {
        if (appraisal) {
            const formData: { [index: string]: { rating: Rating; comments: string } } = {};

            if (appraisal.lineMangerAppraisal) {
                for (const key in appraisal.lineMangerAppraisal.ratings) {
                    formData[key] = {
                        rating: appraisal.lineMangerAppraisal.ratings[key].rating,
                        comments: appraisal.lineMangerAppraisal.ratings[key].comments,
                    };
                }
            } else {
                for (const key in appraisal.selfAppraisal?.ratings) {
                    formData[key] = {
                        rating: appraisal.selfAppraisal.ratings[key].rating,
                        comments: appraisal.selfAppraisal.ratings[key].comments,
                    };
                }
            }

            return formData;
        }

        return {};
    }, [appraisal]);

    const getCompetencyNames = () => {
        const competencies: string[] = [];

        if (appraisal.lineMangerAppraisal) {
            for (const key in appraisal?.lineMangerAppraisal.ratings) {
                competencies.push(key);
            }
        } else {
            for (const key in appraisal?.selfAppraisal.ratings) {
                competencies.push(key);
            }
        }

        return competencies;
    };

    const RatedFeedbackDisplay = () => {
        if (appraisal) {
            if (
                appraisal.approvalStatus === ApprovalStatus.Approved ||
                appraisal?.relationship !== AppraisalRelation.OwnAppraisal
            ) {
                return (
                    <>
                        {appraisal.roleDocumentUrl && (
                            <a
                                className="c-button u-marg-bottom"
                                href={appraisal.roleDocumentUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Competency Definitions
                            </a>
                        )}
                        <RatingForm
                            competencies={getCompetencyNames()}
                            data={initialData}
                            selfAppraisal={appraisal.selfAppraisal.ratings}
                            display={true}
                            appraisalRelationship={appraisal.relationship}
                            additionalComments={appraisal.additionalComments}
                        />
                    </>
                );
            } else {
                return (
                    <>
                        {appraisal.roleDocumentUrl && (
                            <a
                                className="c-button u-marg-bottom"
                                href={appraisal.roleDocumentUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Competency Definitions
                            </a>
                        )}
                        <RatingForm
                            competencies={getCompetencyNames()}
                            data={initialData}
                            display={true}
                            appraisalRelationship={appraisal.relationship}
                            additionalComments={appraisal.additionalComments}
                        />
                    </>
                );
            }
        } else {
            return (
                <div className="c-alert u-fill-warn">You do not have access to this Appraisal</div>
            );
        }
    };

    const abortAppraisal = async () => {
        await ApiClient.appraisals.abortAppraisal(appraisal.id);
        history.push("../");
    };

    const DisplayAppraisal = () => {
        if (appraisal) {
            if (appraisal.relationship !== AppraisalRelation.Peer) {
                return (
                    <>
                        <div className="cl-main__header">
                            <div className="cc-page-header">
                                <h1 className="cc-page-header__title c-heading +h1">
                                    Appraisal for {appraisal?.employee.firstName}{" "}
                                    {appraisal?.employee.lastName}
                                </h1>
                                {appraisal.relationship === AppraisalRelation.Appraiser && (
                                    <div className="cc-page-header__actions">
                                        {!appraisal.appraiserFeedbackComplete && (
                                            <Link
                                                to={
                                                    "/appraisals/" +
                                                    appraisal.id +
                                                    "/line-manger-appraisal"
                                                }
                                                className="c-button u-marg-right"
                                            >
                                                Appraiser Assessment
                                            </Link>
                                        )}
                                        {!appraisal.appraisalComplete && (
                                            <Button
                                                color={Button.Color.Negative}
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            "Are you sure you would like to cancel this appraisal?"
                                                        )
                                                    ) {
                                                        abortAppraisal();
                                                    }
                                                }}
                                            >
                                                Cancel Appraisal
                                            </Button>
                                        )}
                                    </div>
                                )}
                                {appraisal.relationship === AppraisalRelation.OwnAppraisal &&
                                    !appraisal.selfAppraisalComplete && (
                                        <div className="cc-page-header__actions">
                                            <Link
                                                to={
                                                    "/appraisals/" +
                                                    appraisal.id +
                                                    "/self-appraisal"
                                                }
                                                className="c-button"
                                            >
                                                Complete Assessment
                                            </Link>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <div className="cl-main__primary">
                            <ul className="u-marg-bottom">
                                <li>
                                    <b>Status:</b>{" "}
                                    {appraisal.appraisalComplete ? (
                                        <span className="u-color-pos">Completed</span>
                                    ) : (
                                        <span className="u-color-warn">Ongoing</span>
                                    )}
                                </li>
                                <li>
                                    <b>Start Date:</b>{" "}
                                    {dayjs(appraisal.appraisalStartDate).format("DD/MM/YY")}
                                </li>
                                {appraisal.appraisalComplete && (
                                    <li>
                                        <b>Complete Date:</b>{" "}
                                        {dayjs(appraisal.appraisalCompleteDate).format("DD/MM/YY")}
                                    </li>
                                )}

                                <li className="u-marg-top">
                                    <b>Quarter:</b> {appraisal.quarterYear}
                                </li>
                                <li>
                                    <b>Role:</b> {appraisal.jobRoleName}
                                </li>
                                <li>
                                    <b>Grade:</b> {appraisal.gradeName}
                                </li>
                                <li>
                                    <b>Appraiser:</b> {appraisal.appraiser.firstName}{" "}
                                    {appraisal.appraiser.lastName}
                                </li>
                            </ul>
                            <RatedFeedbackDisplay />
                            <div className="cc-panel +1 +max-width u-marg-top-large">
                                {appraisal.appraisalComplete ? (
                                    <DisplayRoleChanges />
                                ) : (
                                    //Guard role changes if appraisal is still open
                                    (appraisal.relationship === AppraisalRelation.Appraiser ||
                                        appraisal.relationship ===
                                            AppraisalRelation.HrOrDirector) && (
                                        <DisplayRoleChanges />
                                    )
                                )}
                                <GuardedContainer
                                    requiredPermissions={[DirectorPermissions.Manage]}
                                >
                                    {appraisal.approvalStatus !== ApprovalStatus.Approved && (
                                        <div className="cc-panel__button-bar">
                                            <Link
                                                to={"/appraisals/" + appraisal.id + "/change-role"}
                                                className="c-button"
                                            >
                                                CHANGE ROLE
                                            </Link>
                                        </div>
                                    )}
                                </GuardedContainer>
                            </div>
                            <div className="cc-panel +1 +max-width">
                                {appraisal.approvalStatus === ApprovalStatus.Approved && (
                                    <DisplayTakeAway />
                                )}
                            </div>
                            <GuardedContainer requiredPermissions={[DirectorPermissions.Manage]}>
                                {appraisal.approvalStatus !== ApprovalStatus.Approved && (
                                    <div className="cc-panel +max-width u-align-center u-marg-top-large u-marg-bottom-large">
                                        <Button
                                            className="c-button +1"
                                            onClick={async (e: { preventDefault: () => void }) => {
                                                e.preventDefault();
                                                if (appraisal) {
                                                    await ApiClient.appraisals.approveAppraisal(
                                                        appraisal
                                                    );
                                                    history.push("/appraisals");
                                                }
                                            }}
                                        >
                                            APPROVE
                                        </Button>
                                        <Button
                                            className="u-marg-left-large"
                                            onClick={async (e: { preventDefault: () => void }) => {
                                                e.preventDefault();
                                                if (appraisal) {
                                                    await ApiClient.appraisals.rejectAppraisal(
                                                        appraisal
                                                    );
                                                    history.push("/appraisals");
                                                }
                                            }}
                                        >
                                            REQUEST REVISION
                                        </Button>
                                    </div>
                                )}
                            </GuardedContainer>
                        </div>
                        <div className="cl-main__secondary">
                            <DisplayPeerFeedBack />
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="c-alert u-fill-warn">
                        You do not have access to this Appraisal
                    </div>
                );
            }
        } else {
            return (
                <div className="c-alert u-fill-warn">You do not have access to this Appraisal</div>
            );
        }
    };

    const updateTakeAway = async (takeAway: string, complete: boolean) => {
        appraisal.takeAway = takeAway;
        if (complete) {
            appraisal.reviewMeetingComplete = true;
            appraisal.appraisalComplete = true;
            applyRoleChanges();
            await ApiClient.appraisals.updateAppraisal(appraisal);
            window.alert("Appraisal has been marked complete");
        } else {
            await ApiClient.appraisals.updateAppraisal(appraisal);
            window.alert("Your takeaways have been saved");
        }

        setReload(reload + 1);
    };

    const dangerousHTMLMarkup = (item: string) => {
        return { __html: item };
    };

    const DisplayTakeAway = () => {
        if (
            !appraisal.appraisalComplete &&
            appraisal.relationship !== AppraisalRelation.HrOrDirector
        ) {
            return (
                <div className="cc-panel">
                    <h2 className="c-heading +h2">Takeaways</h2>
                    <div className="cc-panel +2 +x-tight">
                        <Formik
                            initialValues={{
                                takeAway: appraisal.takeAway,
                            }}
                            onSubmit={async (values, { setErrors }) => {
                                updateTakeAway(values.takeAway, true);
                            }}
                            validationSchema={Yup.object().shape({
                                takeAway: Yup.string().required(
                                    "Takeaway comments are required to complete appraisal"
                                ),
                            })}
                        >
                            {({ values, handleSubmit }) => {
                                return (
                                    <Form>
                                        {appraisal.relationship ===
                                            AppraisalRelation.OwnAppraisal ||
                                            (appraisal.relationship ===
                                                AppraisalRelation.Appraiser && (
                                                <>
                                                    <FormikMorseField field="takeAway">
                                                        <Label htmlFor="TakeAways">
                                                            Which areas do you want to focus on
                                                            next?
                                                        </Label>
                                                        <QuillEditor name="takeAway" />
                                                    </FormikMorseField>

                                                    <Button
                                                        className="u-marg-top"
                                                        /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                                                        onClick={(e: any) => {
                                                            /*Adding a confirm to prevent the user from consistently saving their takeaways which
                                                            in turn would spam notifications to the appraiser*/
                                                            if (
                                                                window.confirm(
                                                                    "Are you sure you want to update your takeaways?"
                                                                )
                                                            ) {
                                                                updateTakeAway(
                                                                    values.takeAway,
                                                                    false
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                    {appraisal.relationship ===
                                                        AppraisalRelation.Appraiser && (
                                                        <Button
                                                            className="u-marg-top"
                                                            color={Button.Color.Primary}
                                                            /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                                                            onClick={(e: any) => {
                                                                handleSubmit();
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            Mark appraisal complete
                                                        </Button>
                                                    )}
                                                </>
                                            ))}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="cc-panel">
                    <h2 className="c-heading +h2">Takeaways</h2>
                    {appraisal.takeAway ? (
                        <div
                            className="cc-panel cc-appraisal__feedback +2 +x-tight"
                            dangerouslySetInnerHTML={dangerousHTMLMarkup(appraisal.takeAway)}
                        />
                    ) : (
                        <div>
                            <div className="cc-panel +2 +x-tight">
                                <p>No takeaway comments given yet!</p>
                            </div>
                            {!appraisal.appraisalComplete && (
                                <Button
                                    color={Button.Color.Primary}
                                    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
                                    onClick={(e: any) => {
                                        if (
                                            window.confirm(
                                                "Are you sure you want to mark Appraisal Complete?"
                                            )
                                        ) {
                                            updateTakeAway(appraisal.takeAway, true);
                                        }
                                    }}
                                >
                                    Mark appraisal complete
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            );
        }
    };

    const DisplayRoleChanges = () => {
        const roleChanged = appraisal.nextRole?.name !== appraisal.jobRoleName;
        const gradeChanged = appraisal.nextRole?.grade?.name !== appraisal.gradeName;
        const levelChanged = appraisal.nextRole?.grade?.level !== appraisal.level;

        const roleChanges = () => {
            return (
                <div className="u-marg-bottom">
                    {roleChanged && (
                        <div className="cc-panel +2 +x-tight">
                            <h2 className="c-heading +h2">Next Role:</h2>
                            <div className="cc-panel__body">{appraisal?.nextRole?.name}</div>
                        </div>
                    )}
                    {gradeChanged && (
                        <div className="cc-panel +2 +x-tight">
                            <h2 className="c-heading +h2">Next Grade:</h2>
                            <div className="cc-panel__body">{appraisal?.nextRole?.grade?.name}</div>
                        </div>
                    )}
                    {levelChanged && (
                        <div className="cc-panel +2 +x-tight">
                            <h2 className="c-heading +h2">Next Level:</h2>
                            <div className="cc-panel__body">
                                {appraisal?.nextRole?.grade?.level}
                            </div>
                        </div>
                    )}
                </div>
            );
        };
        return (
            <div className="cc-panel">
                <>
                    {appraisal.appraisalComplete ? (
                        <h2 className="c-heading +h2">Role Changes</h2>
                    ) : (
                        <h2 className="c-heading +h2">Pending Role Changes</h2>
                    )}
                    {!roleChanged && !gradeChanged && !levelChanged ? (
                        <div className="cc-panel +2 +x-tight u-marg-bottom">None</div>
                    ) : (
                        roleChanges()
                    )}
                </>
            </div>
        );
    };

    const applyRoleChanges = async () => {
        const values = {
            id: appraisal.employee.id,
            roleId: appraisal.nextRole?.id,
            gradeId: appraisal.nextRole?.grade?.id,
            level: appraisal.nextRole?.grade?.level,
        };

        try {
            await ApiClient.employees.update(values);
        } catch (e) {
            if (e.response?.data?.errors) {
                //error handling
            }
        }
    };

    return (
        <Route path="/appraisals/:appraisalId">
            {appraisal && (
                <GuardedRoute
                    path={"/appraisals/" + appraisal.id + "/change-role"}
                    requiredPermissions={[DirectorPermissions.Manage]}
                >
                    <RoleChangeDialog
                        initialValues={appraisal.nextRole ?? {}}
                        onSubmit={async (values, setErrors) => {
                            try {
                                appraisal.nextRole = values;
                                await ApiClient.appraisals.updateAppraisal(appraisal);
                                history.push("/appraisals/" + appraisal.id);
                            } catch (e) {
                                if (e.response?.data?.errors) {
                                    setErrors(e.response.data.errors);
                                }
                            }
                        }}
                        onDismiss={() => {
                            history.push("/appraisals/" + appraisal.id);
                        }}
                    />
                </GuardedRoute>
            )}
            <DisplayAppraisal />
        </Route>
    );
};
