import { ApiClient } from "../../api/ApiClient";
import { AppraisalSystemFetchingSuspense } from "../../app-container/components/suspense/AppraisalSystemFetchingSuspense";
import { Switch, Route } from "react-router-dom";
import { GivePeerFeedBack } from "./GivePeerFeedBack";
import { AppraisalsReview } from "./AppraisalsReview";
import { SelfAppraisal } from "./SelfAppraisal";
import { LineMangerAppraisal } from "./LineMangerAppraisal";
import { useFetch } from "@gcdtech/acorn-react-core";

export const Appraisal = (props: { appraisalId: number }) => {
    const appraisal = useFetch(ApiClient.appraisals.getAppraisal, {
        autoFetchArguments: [props.appraisalId],
    });

    return (
        <AppraisalSystemFetchingSuspense itemFetchingStatus={appraisal}>
            <Switch>
                <Route
                    path="/appraisals/:appraisalId/peer-feedback"
                    exact={false}
                    render={() => <GivePeerFeedBack appraisal={appraisal.item} />}
                />
                <Route
                    path="/appraisals/:appraisalId/self-appraisal"
                    exact={false}
                    render={() => <SelfAppraisal appraisal={appraisal.item} />}
                />
                <Route
                    path="/appraisals/:appraisalId/line-manger-appraisal"
                    exact={false}
                    render={() => <LineMangerAppraisal appraisal={appraisal.item} />}
                />
                <Route render={() => <AppraisalsReview appraisal={appraisal.item} />} />
            </Switch>
        </AppraisalSystemFetchingSuspense>
    );
};
