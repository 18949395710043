import { useFormikContext } from "formik";
import { AddControlProps, InputValue, Select, SelectProps } from "morse-react";
import { ComponentType } from "react";

type FormikMorseSelectProps<
    T,
    V,
    Multiple extends boolean,
    AC extends ComponentType<AddControlProps<V>>
> = SelectProps<T, V, Multiple, AC>;

export const FormikMorseSelect = <
    T,
    V = InputValue,
    Multiple extends boolean = false,
    AC extends ComponentType<AddControlProps<V>> = ComponentType<AddControlProps<V>>
>(
    props: FormikMorseSelectProps<T, V, Multiple, AC>
) => {
    const formContext = useFormikContext();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, ...selectProps } = props;
    // @ts-ignore
    const value = formContext.values[name];

    return (
        <Select<T, V, Multiple>
            {...selectProps}
            value={value}
            name={name}
            onChange={(value?: V | V[]) => formContext.setFieldValue(name ?? "", value)}
        />
    );
};
