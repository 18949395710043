import { Route, Switch, useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { GuardedRoute, useFetch } from "@gcdtech/acorn-react-core";
import { RolePermissions } from "types";
import { ApiClient } from "../../api/ApiClient";
import { RoleDialog } from "./RoleDialog";
import { EditRole } from "./EditRole";

export const RoleManagement = () => {
    const history = useHistory();
    const roles = useFetch(ApiClient.roles.getRoles);

    const onRoleSaved = async () => {
        await roles.refetch();
        history.push("/roles");
    };

    const DefaultView = () => {
        if (roles.item.length > 0) {
            //Default to first tab if it exists
            const roleId = roles.item[0].id;
            return <Redirect to={"/roles/" + roleId} />;
        } else {
            return <div />;
        }
    };

    const roleList = roles.item?.map((role) => {
        return (
            <Switch key={role.id}>
                <Route path={"/roles/" + role.id}>
                    <Link className="c-tab-item +current u-color-text" to={"/roles/" + role.id}>
                        {role.name}
                    </Link>
                </Route>
                <Route path={["/roles/:roleId", "/roles/new"]}>
                    <Link className="c-tab-item u-color-text" to={"/roles/" + role.id}>
                        {role.name}
                    </Link>
                </Route>
                <Route path="/roles">
                    <Link className="c-tab-item u-color-text" to={"/roles/" + role.id}>
                        {role.name}
                    </Link>
                </Route>
            </Switch>
        );
    });

    return (
        <GuardedRoute path="/roles" exact={false} requiredPermissions={[RolePermissions.Manage]}>
            <Route path={["/roles", "/roles/"]} exact={true}>
                <DefaultView />
            </Route>
            <div className="cl-main__header">
                <div className="cc-page-header">
                    <Link to="/" className="cc-page-header__back">
                        Back to dashboard
                    </Link>
                    <h1 className="cc-page-header__title c-heading +h1 u-marg-bottom">
                        Role Management
                    </h1>
                    <div className="cc-page-header__actions">
                        <Link to="/roles/new" className="c-button +1">
                            Add New Role
                        </Link>
                    </div>
                </div>
            </div>
            <div className="cl-main__primary">
                <div className="c-tabbed">
                    <div className="c-tabs" role="tablist" id="rolesTable">
                        {roleList}
                    </div>
                    <div className="c-tab-content u-block">
                        <Switch>
                            <Route path="/roles/new" exact={true}>
                                <RoleDialog
                                    title="Add New Role"
                                    initialValues={{ name: "" }}
                                    existingNames={roles.item?.map((role) =>
                                        role.name.toLowerCase()
                                    )}
                                    inputLabel="Name"
                                    buttonName="Add"
                                    onSubmit={(values) => {
                                        history.push("/roles/new/" + values.name);
                                    }}
                                    onDismiss={() => {
                                        history.push("/roles");
                                    }}
                                />
                            </Route>
                            <Route
                                path="/roles/new/:roleName"
                                render={({ match }) => {
                                    const roleName = match.params.roleName;
                                    const roleData = { id: -1, name: roleName, grades: [] };

                                    return (
                                        <EditRole
                                            existingRole={false}
                                            roleData={roleData}
                                            onSaved={async () => {
                                                await onRoleSaved();
                                            }}
                                        />
                                    );
                                }}
                            />
                            <Route
                                path="/roles/:roleId"
                                render={({ match }) => {
                                    const roleId = match.params.roleId;
                                    const roleData = roles.item.find(
                                        (role) => role.id.toString() === roleId
                                    );
                                    if (roleData) {
                                        return (
                                            <EditRole
                                                key={roleId}
                                                existingRole={true}
                                                roleData={roleData}
                                                onSaved={async () => {
                                                    await onRoleSaved();
                                                }}
                                            />
                                        );
                                    } else {
                                        return <DefaultView />;
                                    }
                                }}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </GuardedRoute>
    );
};
