const PositivePill = () => {
    return <span className="u-color-pos">Completed</span>;
};
const NegativePill = () => {
    return <span className="u-color-warn">Incomplete</span>;
};

export const TruthPill = (props: { value: boolean }) => {
    return props.value ? <PositivePill /> : <NegativePill />;
};
