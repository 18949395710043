import { RatingInputs } from "./RatingInputs";
import { Form, Formik } from "formik";
import { FormikMorseField } from "../../../app-container/components/FormikMorseField";
import { Button } from "morse-react";
import { AppraisalRelation, Rating } from "types";
import * as Yup from "yup";
import { QuillEditor } from "../.././appraisals/QuillEditor";

export type RatingFormValues = { rating: string; comments: string };
export type RatingFormValuesPairs = { [index: string]: { rating: Rating; comments: string } };

type ratings = {
    [key: string]: {
        competency: string;
        rating: Rating;
        comments: string;
    };
};

type RatingFormProps = {
    competencies: string[];
    data: RatingFormValuesPairs;
    onSaveDraft?: (values: RatingFormValuesPairs) => void;
    onSubmit?: (values: RatingFormValuesPairs) => void;
    selfAppraisal?: ratings;
    display?: boolean;
    appraisalRelationship: AppraisalRelation;
    additionalComments: string;
};

export const RatingForm = (props: RatingFormProps) => {
    const yupShape: { [index: string]: unknown } = {};
    const data: { [index: string]: string } = {
        "additional-comments": props.additionalComments ?? "",
    };

    for (const competency of props.competencies) {
        data[competency + "-Rating"] = props.data[competency].rating;
        const comment = props.data[competency].comments.trim().toLowerCase();
        if (comment !== "n/a") {
            data[competency + "-Comments"] = props.data[competency].comments;
        } else {
            data[competency + "-Comments"] = "";
        }

        yupShape[competency + "-Rating"] = Yup.string().not(
            ["Unrated"],
            "You must choose a rating"
        );
        yupShape[competency + "-Comments"] = Yup.string().required("You must leave a comment");
    }

    const transformFormValuesToRatingFormValuePairs = (values: {
        [index: string]: string;
    }): RatingFormValuesPairs => {
        const valuePairs: RatingFormValuesPairs = {};

        for (const competency of props.competencies) {
            valuePairs[competency] = {
                rating: values[competency + "-Rating"] as Rating,
                comments: values[competency + "-Comments"],
            };
        }

        if (props.appraisalRelationship === AppraisalRelation.Appraiser) {
            valuePairs["additional-comments"] = {
                rating: Rating.Unrated,
                comments: values["additional-comments"],
            };
        }

        return valuePairs;
    };

    const onSaveDraft = props.onSaveDraft;
    const onSubmit = props.onSubmit;

    const dangerousHTMLMarkup = (item: string) => {
        return { __html: item };
    };

    //Tests if a form is being created or if a display for feedback instead
    if (onSaveDraft !== undefined && onSubmit !== undefined && !props.display) {
        return (
            <Formik
                initialValues={data}
                // @ts-ignore
                validationSchema={Yup.object().shape(yupShape)}
                onSubmit={(values) => {
                    onSubmit(transformFormValuesToRatingFormValuePairs(values));
                }}
            >
                {({ handleSubmit, setFieldValue, values, handleChange }) => (
                    <Form>
                        {props.competencies.map((competency) => {
                            return (
                                <RatingInputs
                                    key={competency}
                                    competency={competency}
                                    rating={Rating.Unrated}
                                    comment={values[competency + "-Comments"]}
                                    handleChange={handleChange(competency + "-Comments")}
                                    selfRating={
                                        props.selfAppraisal
                                            ? props.selfAppraisal[competency].rating
                                            : undefined
                                    }
                                    selfComment={
                                        props.selfAppraisal
                                            ? props.selfAppraisal[competency].comments
                                            : undefined
                                    }
                                />
                            );
                        })}
                        {props.appraisalRelationship === AppraisalRelation.Appraiser && (
                            <div className="cc-panel +1 +max-width">
                                <h2 className="c-heading +h2">Additional Comments</h2>
                                <FormikMorseField field={"additional-comments"}>
                                    <QuillEditor name={"additional-comments"} />
                                </FormikMorseField>
                            </div>
                        )}

                        <div className="c-button-bar u-marg-top-large">
                            <Button
                                type="button"
                                color={Button.Color.Primary}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Submit Assessment
                            </Button>
                            <Button
                                type="button"
                                color={Button.Color.Quiet}
                                onClick={() => {
                                    onSaveDraft(transformFormValuesToRatingFormValuePairs(values));
                                }}
                            >
                                Save Draft
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    } else {
        return (
            <ul>
                {props.competencies.map((competency) => {
                    return (
                        <RatingInputs
                            key={competency}
                            competency={competency}
                            rating={data[competency + "-Rating"] as Rating}
                            comment={data[competency + "-Comments"]}
                            display={true}
                            selfRating={
                                props.selfAppraisal
                                    ? props.selfAppraisal[competency].rating
                                    : undefined
                            }
                            selfComment={
                                props.selfAppraisal
                                    ? props.selfAppraisal[competency].comments
                                    : undefined
                            }
                        />
                    );
                })}
                {props.additionalComments && (
                    <div className="cc-panel +1 +max-width">
                        <h2 className="c-heading +h2">Additional Comments</h2>

                        <div
                            className="cc-panel cc-appraisal__feedback +2 +tighter u-marg-top"
                            dangerouslySetInnerHTML={dangerousHTMLMarkup(props.additionalComments)}
                        />
                    </div>
                )}
            </ul>
        );
    }
};
