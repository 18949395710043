import { useFormikContext } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export type EditorValue = {
    valueText: string;
};

export type QuillEditorProps = {
    name: string;
};

export const QuillEditor = (props: QuillEditorProps) => {
    const formContext = useFormikContext();
    //@ts-ignore
    const value = formContext.values[props.name];
    //@ts-ignore
    function handleBody(content: string, ...args) {
        formContext.setFieldValue(props.name ?? "", content);
    }

    return (
        <ReactQuill
            className="cc-appraisal__text-area"
            placeholder="Write here..."
            modules={QuillModules}
            formats={QuillFormats}
            onChange={handleBody}
            value={value}
        />
    );
};

export const QuillModules = {
    toolbar: [
        [{ header: [1, 2, 3] }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
    ],
};

export const QuillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];
