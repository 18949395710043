import { useFormikContext } from "formik";
import { Field, FieldProps } from "morse-react";
import React from "react";
import { FormikMorseInput } from "./FormikMorseInput";

type FormikMorseFieldProps = FieldProps & {
    field: string;
};

/**
 * Helper component to reduce boilerplate code in building forms with inputs
 * that require feedback.
 *
 * This component won't work outside a Formik form.
 */
export const FormikMorseField = ({ field, children, ...inputProps }: FormikMorseFieldProps) => {
    const formikContext = useFormikContext<{ [index: string]: unknown }>();

    const fieldParts = field.split(".");

    let touched = false;
    let error = "";

    for (const part of fieldParts) {
        if (formikContext.touched) {
            touched = formikContext.touched[part] ?? false;
        }

        if (formikContext.errors) {
            error = formikContext.errors[part] ?? "";
        }
    }

    // If the field is touched and there's an error, set const to the string value of that error
    const feedbackText = (formikContext.submitCount > 0 || touched) && error ? error : undefined;

    const feedback = !!feedbackText ? Field.Feedback.Error : undefined;

    return (
        <Field feedback={feedback} feedbackText={feedbackText} {...inputProps}>
            {children ?? <FormikMorseInput name={field} />}
        </Field>
    );
};
