import { Route, Switch } from "react-router-dom";
import { AppraisalsOversight } from "./AppraisalsOversight";
import { GuardedRoute } from "@gcdtech/acorn-react-core";
import { AppraisalPermissions } from "types";
import { Appraisal } from "./Appraisal";

export const Appraisals = () => {
    return (
        <Route path="/appraisals" exact={false}>
            <Switch>
                <Route
                    path="/appraisals/:appraisalId(\d+)"
                    exact={false}
                    render={({ match }) => (
                        <Appraisal appraisalId={parseInt(match.params.appraisalId)} />
                    )}
                />
                <GuardedRoute
                    path="/appraisals"
                    exact={false}
                    requiredPermissions={[AppraisalPermissions.Manage]}
                >
                    <AppraisalsOversight />
                </GuardedRoute>
            </Switch>
        </Route>
    );
};
