type FractionCompleteProps = {
    peerFeedbackComplete: boolean;
    numberComplete: number;
    totalPeers: number;
};

export const FractionPeerFeebackComplete = (props: FractionCompleteProps) => {
    if (props.peerFeedbackComplete) {
        return <span className="c-alert u-marg-top u-fill-pos">Peer Feedback Complete</span>;
    } else {
        return (
            <span className="c-alert u-marg-top u-fill-warn">
                {props.numberComplete}/{props.totalPeers} Complete
            </span>
        );
    }
};
