import { Field, FieldProps, useFormikContext } from "formik";
import { Input, InputProps } from "morse-react";
import React, { ElementType } from "react";

type FormikMorseInputProps<V> = InputProps & FieldProps<V>;

const FormikCompatibleInput = <V extends never>(props: FormikMorseInputProps<V>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, field, type = "text", form, meta, ...inputProps } = props;

    return <Input<"input"> id={id} type={type} {...field} {...inputProps} />;
};

export const FormikMorseInput = <V extends ElementType = "input">(
    props: InputProps<V> & { name: string }
) => {
    const formikContext = useFormikContext();
    const meta = formikContext.getFieldMeta(props.name);

    return <Field {...props} meta={meta} component={FormikCompatibleInput} />;
};
