import { Button } from "morse-react";
import { useState } from "react";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Appraisal, AppraisalRelation, Rating } from "types";
import { ApiClient } from "../../api/ApiClient";
import { TruthPill } from "../../app-container/components/TruthPill";
import { FractionPeerFeebackComplete } from "./components/FractionPeerFeedbackComplete";
import { RatingForm, RatingFormValuesPairs } from "./components/RatingForm";

export const LineMangerAppraisal = (props: { appraisal: Appraisal }) => {
    const { appraisal } = props;
    const history = useHistory();
    const [peerFeedbackWarrning, setPeerFeedbackWarrning] = useState<string>();

    const initialData = useMemo(() => {
        if (appraisal) {
            const formData: { [index: string]: { rating: Rating; comments: string } } = {};

            for (const key in appraisal.lineMangerAppraisal.ratings) {
                formData[key] = {
                    rating: appraisal.lineMangerAppraisal.ratings[key].rating,
                    comments: appraisal.lineMangerAppraisal.ratings[key].comments,
                };
            }

            return formData;
        }

        return {};
    }, [appraisal]);

    const getCompetencyNames = () => {
        const competencies: string[] = [];

        for (const key in appraisal?.lineMangerAppraisal.ratings) {
            competencies.push(key);
        }

        return competencies;
    };

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const saveLineMangerAppraisal = async (values: RatingFormValuesPairs, submit: boolean) => {
        if (appraisal) {
            for (const key in appraisal?.lineMangerAppraisal.ratings) {
                // @ts-ignore
                appraisal.lineMangerAppraisal.ratings[key].rating = values[key].rating;
                appraisal.lineMangerAppraisal.ratings[key].comments = values[key].comments;
            }
            appraisal.additionalComments = values["additional-comments"].comments;

            if (submit) {
                if (appraisal.peerFeedbackComplete) {
                    await ApiClient.appraisals.submitLineMangerAppraisal(
                        appraisal,
                        appraisal.lineMangerAppraisal
                    );
                    window.alert("Your feedback has been submitted");
                    history.push("../");
                } else {
                    setPeerFeedbackWarrning(
                        "Peer feedback is not complete. At least 2 must be complete to mark as complete"
                    );
                    return;
                }
            } else {
                await ApiClient.appraisals.saveLineMangerAppraisal(
                    appraisal,
                    appraisal.lineMangerAppraisal
                );
                window.alert("Draft has been saved");
            }
        }
    };

    const setPeerFeedBackComplete = () => {
        if (appraisal.numberPeerFeedBackComplete >= 2) {
            appraisal.peerFeedbackComplete = true;
            setPeerFeedbackWarrning("");
        }
    };

    const dangerousHTMLMarkup = (item: string) => {
        return { __html: item };
    };

    const DisplayPeerFeedBack = () => {
        const peers = appraisal?.peers.map((peer) => {
            if (appraisal.peerFeedbackComplete) {
                return (
                    <li key={peer.name}>
                        <span className="cc-peer__avatar" />
                        <span className="cc-peer__name-group">
                            <span className="cc-peer__name">{peer.name}</span>
                        </span>
                    </li>
                );
            } else {
                return (
                    <li key={peer.name}>
                        <span className="cc-peer__avatar" />
                        <span className="cc-peer__name-group">
                            <span className="cc-peer__name">{peer.name}</span>
                            {/*// @ts-ignore */}
                            <TruthPill value={peer.submitted} />
                        </span>
                    </li>
                );
            }
        });
        if (appraisal?.peerFeedBack) {
            const praise = appraisal.peerFeedBack.praise.map((praise) => {
                const praises = praise.split("\n");
                return (
                    <>
                        <li className="cc-panel cc-appraisal__feedback +1b +x-tight" key={praise}>
                            <div dangerouslySetInnerHTML={dangerousHTMLMarkup(praises[0])} />
                        </li>
                        <li className="cc-panel cc-appraisal__feedback +1b +x-tight" key={praise}>
                            <div dangerouslySetInnerHTML={dangerousHTMLMarkup(praises[1])} />
                        </li>
                    </>
                );
            });

            const critique = appraisal.peerFeedBack.criique.map((critique) => {
                const critiques = critique.split("\n");
                return (
                    <>
                        <li className="cc-panel cc-appraisal__feedback +1b +x-tight" key={critique}>
                            <div dangerouslySetInnerHTML={dangerousHTMLMarkup(critiques[0])} />
                        </li>
                        <li className="cc-panel cc-appraisal__feedback +1b +x-tight" key={critique}>
                            <div dangerouslySetInnerHTML={dangerousHTMLMarkup(critiques[1])} />
                        </li>
                    </>
                );
            });

            return (
                <>
                    <div className="cc-panel">
                        <h2 className="c-heading +h2">Peers</h2>
                        <ul className="cc-peers">{peers}</ul>
                    </div>
                    <div className="cc-panel">
                        <h2 className="c-heading +h2">Peer Feedback</h2>
                        <div className="cc-panel +2 +tighter">
                            <h3 className="c-heading +h3">Praise</h3>
                            <ul className="u-marg-bottom-large">{praise}</ul>
                        </div>
                        <div className="cc-panel +2 +tighter">
                            <h3 className="c-heading +h3">Opportunity for Growth</h3>
                            <ul>{critique}</ul>
                        </div>
                    </div>
                    {!appraisal.peerFeedbackComplete && (
                        <FractionPeerFeebackComplete
                            peerFeedbackComplete={appraisal.peerFeedbackComplete}
                            numberComplete={appraisal.numberPeerFeedBackComplete}
                            totalPeers={appraisal.peers.length}
                        />
                    )}
                    {!appraisal.peerFeedbackComplete &&
                        appraisal.numberPeerFeedBackComplete >= 2 && (
                            <Button onClick={() => setPeerFeedBackComplete()}>
                                Mark peer feedback complete
                            </Button>
                        )}
                </>
            );
        } else {
            return (
                <>
                    <div className="cc-panel">
                        <h2 className="c-heading +h2">Peers</h2>
                        <ul className="cc-peers">{peers}</ul>
                    </div>
                    <div className="cc-panel">
                        <h2 className="c-heading +h2">Peer Feedback</h2>
                        <div className="cc-panel">There is no peer feed back yet</div>
                    </div>
                </>
            );
        }
    };

    if (appraisal?.relationship === AppraisalRelation.Appraiser) {
        if (appraisal.selfAppraisalComplete) {
            if (!appraisal.appraiserFeedbackComplete) {
                return (
                    <>
                        <div className="cl-main__header">
                            <div className="cc-page-header">
                                <h1 className="cc-page-header__title c-heading +h1">
                                    Appraiser Assessment for {appraisal?.employee.firstName}{" "}
                                    {appraisal?.employee.lastName}
                                </h1>
                            </div>
                        </div>
                        <div className="cl-main__primary">
                            <ul className="u-marg-bottom-large">
                                <li>
                                    <b>Quarter:</b> {appraisal.quarterYear}
                                </li>
                                <li>
                                    <b>Role:</b> {appraisal.jobRoleName}
                                </li>
                                <li>
                                    <b>Grade:</b> {appraisal.gradeName}
                                </li>
                                <li>
                                    <b>Appraiser:</b> {appraisal?.appraiser.firstName}{" "}
                                    {appraisal?.appraiser.lastName}
                                </li>
                            </ul>
                            {appraisal.roleDocumentUrl && (
                                <a
                                    className="c-button u-marg-bottom"
                                    href={appraisal.roleDocumentUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    View Competency Definitions
                                </a>
                            )}
                            {appraisal && (
                                <RatingForm
                                    competencies={getCompetencyNames()}
                                    data={initialData}
                                    onSaveDraft={(values) => {
                                        saveLineMangerAppraisal(values, false);
                                    }}
                                    onSubmit={(values) => {
                                        saveLineMangerAppraisal(values, true);
                                    }}
                                    selfAppraisal={appraisal.selfAppraisal.ratings}
                                    appraisalRelationship={appraisal.relationship}
                                    additionalComments={appraisal.additionalComments}
                                />
                            )}
                        </div>
                        <div className="cl-main__secondary">
                            <p className="u-color-neg">{peerFeedbackWarrning}</p>
                            <DisplayPeerFeedBack />
                        </div>
                    </>
                );
            } else {
                return (
                    <div className="cl-main__header">
                        <div className="cc-page-header">
                            <h1 className="cc-page-header__title c-heading +h1">
                                This Appraiser Assessment is already complete
                            </h1>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <div className="cl-main__header">
                    <div className="cc-page-header">
                        <h1 className="cc-page-header__title c-heading +h1">
                            Appraisee Assessment not yet complete
                        </h1>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="cl-main__header">
                <div className="cc-page-header">
                    <h1 className="cc-page-header__title c-heading +h1">
                        You are not the Appraiser for this Appraisal
                    </h1>
                </div>
            </div>
        );
    }
};
