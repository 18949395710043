import { useEffect, useState } from "react";
import { Appraisal, feedBackRequestResponce } from "types";
import { Link, useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { FormikMorseField } from "../../app-container/components/FormikMorseField";
import * as Yup from "yup";
import { Button, Label } from "morse-react";
import { ApiClient } from "../../api/ApiClient";
import { QuillEditor } from "./QuillEditor";

export const GivePeerFeedBack = (props: { appraisal: Appraisal }) => {
    const { appraisal } = props;
    const history = useHistory();
    const [feedBackRequest, setFeedBackRequest] = useState<feedBackRequestResponce>();
    const [requestSatus, setRequestSatus] = useState<string>("Request closed for ");
    const appraisalId = appraisal?.id;

    useEffect(() => {
        ApiClient.appraisals.getFeedBack(appraisalId).then((response) => {
            if (response.peer !== undefined) {
                setFeedBackRequest(response);
            } else {
                setFeedBackRequest({
                    peer: "",
                    quarter: "",
                    complete: true,
                });
                setRequestSatus("You do not have access to give feedback for this appraisal");
            }
        });
    }, [appraisalId]);

    const DisplayFeedBackRequest = () => {
        if (feedBackRequest?.complete) {
            return (
                <div className="c-alert u-fill-warn">
                    {requestSatus}
                    {feedBackRequest.peer}{" "}
                    <Link
                        className="l-grid__item"
                        to={{
                            pathname: "/",
                        }}
                    >
                        Return to dashboard
                    </Link>
                </div>
            );
        } else {
            return (
                <>
                    <div className="cl-main__header">
                        <div className="cc-page-header">
                            <div className="cc-page-header__back">
                                <a href="/">Back to dashboard</a>
                            </div>
                            <h1 className="c-heading +h1 cc-page-header__title">
                                Peer feedback for {feedBackRequest?.peer} in{" "}
                                {feedBackRequest?.quarter}
                            </h1>
                        </div>
                        <p>
                            Please note - observations must be less that <b>400 characters</b>. This
                            is intentional to ensure it is easily summarised by those involved in
                            the appraisal.
                        </p>
                    </div>
                    <div className="cl-main__primary">
                        <Formik
                            initialValues={{
                                praise1: "",
                                praise2: "",
                                critique1: "",
                                critique2: "",
                            }}
                            validateOnBlur
                            validateOnChange
                            onSubmit={async (values, { setErrors }) => {
                                const praise = values.praise1 + "\n" + values.praise2;
                                const critique = values.critique1 + "\n" + values.critique2;
                                let feedbackGiven = true;
                                if (praise === "\n") {
                                    feedbackGiven = false;
                                    setErrors({
                                        praise1: "Please provide at least 1 praise",
                                    });
                                }
                                if (critique === "\n") {
                                    feedbackGiven = false;
                                    setErrors({
                                        critique1: "Please provide at least 1 critique",
                                    });
                                }
                                if (critique === "\n" && praise === "\n") {
                                    setErrors({
                                        praise1: "Please provide at least 1 praise",
                                        critique1: "Please provide at least 1 critique",
                                    });
                                }

                                if (feedbackGiven) {
                                    try {
                                        await ApiClient.appraisals.submitPeerFeedBack(
                                            praise,
                                            critique,
                                            appraisalId
                                        );

                                        if (feedBackRequest !== undefined) {
                                            setFeedBackRequest({
                                                peer: feedBackRequest.peer,
                                                quarter: feedBackRequest?.quarter,
                                                complete: true,
                                            });
                                            window.alert("Your feedback has been submitted");
                                            history.push("/");
                                        }
                                    } catch (e) {
                                        if (e.response?.data?.errors) {
                                            setErrors(e.response.data.errors);
                                        }
                                    }
                                }
                            }}
                            validationSchema={Yup.object().shape({
                                praise1: Yup.string().max(450, "Must be 400 characters or less"),
                                praise2: Yup.string().max(450, "Must be 400 characters or less"),
                                critique1: Yup.string().max(450, "Must be 400 characters or less"),
                                critique2: Yup.string().max(450, "Must be 400 characters or less"),
                            })}
                        >
                            {({ handleChange, values, errors, setValues, touched }) => {
                                return (
                                    <div className="c-form">
                                        <Form>
                                            <div className="c-form__block">
                                                <fieldset>
                                                    <legend className="c-heading">
                                                        List up to two strengths that{" "}
                                                        {feedBackRequest?.peer} has shown over the
                                                        last quarter
                                                    </legend>
                                                    <FormikMorseField field="praise1">
                                                        <Label htmlFor="praise1">
                                                            First observation
                                                        </Label>
                                                        <QuillEditor name="praise1" />
                                                    </FormikMorseField>
                                                    <FormikMorseField field="praise2">
                                                        <Label htmlFor="praise2">
                                                            Second observation
                                                        </Label>
                                                        <QuillEditor name="praise2" />
                                                    </FormikMorseField>
                                                </fieldset>
                                            </div>
                                            <div className="c-form__block">
                                                <fieldset>
                                                    <legend className="c-heading">
                                                        List up to two opportunities for improvement
                                                        for {feedBackRequest?.peer} that you have
                                                        noticed over the last quarter
                                                    </legend>
                                                    <FormikMorseField field="critique1">
                                                        <Label htmlFor="critique1">
                                                            First observation
                                                        </Label>
                                                        <QuillEditor name="critique1" />
                                                    </FormikMorseField>
                                                    <FormikMorseField field="critique2">
                                                        <Label htmlFor="critique2">
                                                            Second observation
                                                        </Label>
                                                        <QuillEditor name="critique2" />
                                                    </FormikMorseField>
                                                </fieldset>
                                            </div>
                                            <div className="c-form__actions">
                                                <Button color={Button.Color.Primary}>
                                                    Submit FeedBack
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                );
                            }}
                        </Formik>
                    </div>
                </>
            );
        }
    };

    return <DisplayFeedBackRequest />;
};
