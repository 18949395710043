import { Rating } from "types";
import { FormikMorseSelect } from "../../../app-container/components/FormikMorseSelect";
import { FormikMorseField } from "../../../app-container/components/FormikMorseField";
import { Label } from "morse-react";
import { ChangeEvent } from "react";
import { QuillEditor } from "../.././appraisals/QuillEditor";

type RatingProps = {
    rating: Rating;
    competency: string;
    selfRating?: string;
    selfComment?: string;
    comment: string;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    handleChange?: (e: string | ChangeEvent<any>) => void;
    display?: boolean;
};

const dangerousHTMLMarkup = (item: string) => {
    return { __html: item };
};

export const RatingInputs = (props: RatingProps) => {
    //Displays self appraisal in a none editable format
    const displaySelfAppraisal = (selfRating?: string, selfComment?: string) => {
        return (
            <>
                {selfRating && (
                    <div>
                        <h3 className="c-heading +h3">Appraisee Assessment</h3>
                        <span className="u-font-bold">{selfRating}</span>
                    </div>
                )}
                {selfComment && (
                    <div
                        className="cc-panel cc-appraisal__feedback +2 +x-tight u-marg-top"
                        dangerouslySetInnerHTML={dangerousHTMLMarkup(selfComment)}
                    />
                )}
                {!selfComment && props.selfRating && (
                    <div className="cc-panel +2b +x-tight u-marg-top">To be completed</div>
                )}
            </>
        );
    };

    return (
        <div className="cc-panel +1 +max-width">
            <h2 className="c-heading +h2">{props.competency}</h2>
            {/*Test if needed for display or forum input */}
            {props.display && (
                <>
                    {displaySelfAppraisal(props.selfRating, props.selfComment)}
                    <div className="cc-appraisal__heading u-marg-top">
                        {props.selfRating && (
                            <h3 className="c-heading +h3">Appraiser Assessment</h3>
                        )}
                        {!props.selfRating && (
                            <h3 className="c-heading +h3">Appraisee Assessment</h3>
                        )}
                        <span className="u-font-bold">{props.rating}</span>
                    </div>
                    {props.comment && (
                        <div
                            className="cc-panel cc-appraisal__feedback +2 +x-tight u-marg-top"
                            dangerouslySetInnerHTML={dangerousHTMLMarkup(props.comment)}
                        />
                    )}
                    {!props.comment && (
                        <div className="cc-panel +2b +x-tight u-marg-top">To be completed</div>
                    )}
                </>
            )}
            {/*Test if needed for display or forum input */}
            {!props.display && (
                <>
                    {displaySelfAppraisal(props.selfRating, props.selfComment)}
                    <div className="cc-appraisal__heading u-marg-top">
                        {props.selfRating && (
                            <h3 className="c-heading +h3">Appraiser Assessment</h3>
                        )}
                        {!props.selfRating && (
                            <h3 className="c-heading +h3">Appraisee Assessment</h3>
                        )}
                    </div>
                    <FormikMorseField field={props.competency + "-Rating"}>
                        <Label htmlFor={props.competency + "-Rating"}>Your rating</Label>
                        <FormikMorseSelect
                            name={props.competency + "-Rating"}
                            options={Object.values(Rating)}
                        />
                    </FormikMorseField>
                    <FormikMorseField field={props.competency + "-Comments"}>
                        <Label htmlFor={props.competency + "-Comments"}>Your comments</Label>
                        <QuillEditor name={props.competency + "-Comments"} />
                    </FormikMorseField>{" "}
                </>
            )}
        </div>
    );
};
