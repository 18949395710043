import { CrudListComponentProps, useFetchList } from "@gcdtech/acorn-crud-react";
import { Employee } from "types";
import { Button } from "morse-react";
import { Link, Route, useHistory } from "react-router-dom";
import { ApiClient } from "../../../api/ApiClient";
import { AppraisalSystemFetchingSuspense } from "../../../app-container/components/suspense/AppraisalSystemFetchingSuspense";
import { Dialog } from "morse-react-dialogs";
import dayjs from "dayjs";

export const EmployeeList = (props: CrudListComponentProps<Employee>) => {
    const archivedEmployees = useFetchList(ApiClient.employees.listArchivedEmployees);
    const history = useHistory();

    const archiveEmployee = async (employee: Employee) => {
        await ApiClient.employees.archiveEmployee(employee);
        props.crud.listFetchingState.refetch();
        archivedEmployees.refetch();
    };

    const restoreEmployee = async (employee: Employee) => {
        await ApiClient.employees.restoreEmployee(employee);
        props.crud.listFetchingState.refetch();
        archivedEmployees.refetch();
    };

    const archivedEmployeesDom = archivedEmployees.items.map((archEmployee) => {
        return (
            <tr key={archEmployee.lastName}>
                <td className="u-font-bold">
                    <span className="u-flex u-align-items-center">
                        &nbsp;{archEmployee.firstName} {archEmployee.lastName}
                    </span>
                </td>
                <td>
                    <span className="u-translucent">{archEmployee.email}</span>
                </td>
                <td className="u-align-right">
                    <Button
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Are you sure you would like to restore this employee?"
                                )
                            ) {
                                return restoreEmployee(archEmployee);
                            }
                        }}
                    >
                        RESTORE
                    </Button>
                </td>
            </tr>
        );
    });

    const employeesDom = props.items.map((employee) => {
        const roleName = employee.role?.name;
        const gradeName = employee.grade?.name;
        const lastPromoted = employee.lastPromoted;
        const lastAppraisedQuarter = employee.lastAppraisedQuarter;

        return (
            <tr key={employee.lastName}>
                <td className="c-table__cell +primary">
                    <Link to={"/appraisals/employees/" + employee.id}>
                        {employee.firstName} {employee.lastName}
                    </Link>
                </td>
                <td>{employee.email}</td>
                <td>
                    {gradeName !== roleName && gradeName} {roleName}
                </td>
                <td>{employee.level}</td>
                <td>{lastPromoted && <>{dayjs(lastPromoted).toDate().toLocaleDateString()}</>}</td>
                <td>{lastAppraisedQuarter && <>{lastAppraisedQuarter}</>}</td>
                <td className="c-table__cell +tight">
                    <Button
                        color={Button.Color.Quiet}
                        onClick={() => {
                            if (
                                window.confirm(
                                    "Are you sure you would like to archive this employee?"
                                )
                            ) {
                                return archiveEmployee(employee);
                            }
                        }}
                    >
                        Archive
                    </Button>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Route path="/appraisals/archived-employees">
                <Dialog
                    allowDismiss
                    title="Archived Employees"
                    onDismissed={() => {
                        history.push("/appraisals");
                    }}
                    buttons={[]}
                >
                    <AppraisalSystemFetchingSuspense itemFetchingStatus={archivedEmployees}>
                        <table className="c-table +primary-list">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>{archivedEmployeesDom}</tbody>
                        </table>
                    </AppraisalSystemFetchingSuspense>
                </Dialog>
            </Route>

            <table className="c-table +primary-list">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Level</th>
                        <th>Last Promotion</th>
                        <th>Last Quarter</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{employeesDom}</tbody>
            </table>
        </>
    );
};
