import { CreateJobRoleDto, JobRole, JobRoles, UpdateJobRoleDto } from "types";
import { Api } from "@gcdtech/acorn-react-core";

export class RolesApi extends Api {
    getRoles = async (): Promise<JobRoles> => {
        return (await this.api.get<JobRoles>("/roles")).data;
    };

    async createRole(roleData: CreateJobRoleDto): Promise<JobRole> {
        return (await this.api.post<JobRole>("/roles", roleData)).data;
    }

    async updateRole(roleData: UpdateJobRoleDto): Promise<JobRole> {
        return (await this.api.put<JobRole>("/roles/" + roleData.id, roleData)).data;
    }
}
