import { FetchingStatusProps, FetchingSuspense } from "@gcdtech/acorn-react-core";
import { Loader } from "morse-react";

const FetchingDataSuspenseContent = <T,>(props: FetchingStatusProps<T>) => {
    if (!props.itemFetchingStatus.fetched) {
        return (
            <div>
                <p>Loading... Please Wait...</p>
            </div>
        );
    } else {
        return <>{props.children}</>;
    }
};

const FetchingDataSlowlySuspenseContent = <T,>(props: FetchingStatusProps<T>) => {
    if (!props.itemFetchingStatus.fetched) {
        return <FetchingDataSuspenseContent {...props} />;
    } else {
        return (
            <>
                <Loader />
                {props.children}
            </>
        );
    }
};

export const AppraisalSystemFetchingSuspense = <T,>(props: FetchingStatusProps<T>) => {
    return (
        <FetchingSuspense
            fetchingComponent={FetchingDataSuspenseContent}
            fetchingSlowlyComponent={FetchingDataSlowlySuspenseContent}
            {...props}
        />
    );
};
