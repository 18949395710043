import { Employee, EmployeeWithLastAppraisedQuarter, PreviousAppraisalsResponseDto } from "types";
import { EmployeeStatus } from "../types";
import { CrudWithListApi } from "@gcdtech/acorn-crud-react";
import { FetchListResponse, ListCriteria, ListRequest } from "@gcdtech/acorn-common-types";

export class EmployeesApi extends CrudWithListApi<Employee> {
    protected getCollectionUrl(): string {
        return "/employee";
    }

    async listAppraisers(): Promise<Employee[]> {
        return (await this.api.get<Employee[]>("/employee/appraisers")).data;
    }

    async getEmployee(employeeId: string): Promise<Employee> {
        return (await this.api.get<Employee>("/employee/" + employeeId)).data;
    }

    listArchivedEmployees = async (
        request: ListRequest<
            EmployeeWithLastAppraisedQuarter,
            ListCriteria<EmployeeWithLastAppraisedQuarter>,
            keyof EmployeeWithLastAppraisedQuarter
        >
    ): Promise<
        FetchListResponse<
            EmployeeWithLastAppraisedQuarter,
            ListCriteria<EmployeeWithLastAppraisedQuarter>,
            keyof EmployeeWithLastAppraisedQuarter
        >
    > => {
        return (
            await this.api.get<
                FetchListResponse<
                    EmployeeWithLastAppraisedQuarter,
                    ListCriteria<EmployeeWithLastAppraisedQuarter>,
                    keyof EmployeeWithLastAppraisedQuarter
                >
            >("/employee/archived-with-last-appraisal")
        ).data;
    };

    async getUserRole(): Promise<Employee | undefined> {
        try {
            const userRole = (await this.api.get<Employee>("UserRole")).data;
            return userRole;
        } catch (e) {
            return undefined;
        }
    }

    async getMe(): Promise<Employee | undefined> {
        try {
            const employee = (await this.api.get<Employee>("/employee/me")).data;
            return employee;
        } catch (e) {
            return undefined;
        }
    }

    async getEmployeeStatus(): Promise<EmployeeStatus> {
        return (await this.api.get<EmployeeStatus>("/employee/my-status")).data;
    }

    async restoreEmployee(employee: Employee): Promise<Employee> {
        employee.isArchived = false;
        return (await this.api.put<Employee>("/employee/" + employee.id, employee)).data;
    }

    async archiveEmployee(employee: Employee): Promise<Employee> {
        employee.isArchived = true;
        return (await this.api.put<Employee>("/employee/" + employee.id, employee)).data;
    }

    getPreviousAppraisal = async (): Promise<PreviousAppraisalsResponseDto> => {
        return (
            await this.api.get<PreviousAppraisalsResponseDto>("/appraisal/my-previous-appraisal")
        ).data;
    };
}
