import React from "react";
import ReactDOM from "react-dom";
import "./app-container/styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AxiosApiAdapter, getCoreContainer, ApiAdapter } from "@gcdtech/acorn-react-core";
import { ApiClient } from "./api/ApiClient";

const axiosApi = new AxiosApiAdapter();
axiosApi.init(process.env.REACT_APP_API_URL ?? "");

getCoreContainer().bind(ApiAdapter).toConstantValue(axiosApi);

ApiClient.initialise();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
