import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Appraisal, AppraisalRelation } from "types";
import { ApiClient } from "../../api/ApiClient";
import { RatingForm, RatingFormValuesPairs } from "./components/RatingForm";

export const SelfAppraisal = (props: { appraisal: Appraisal }) => {
    const { appraisal } = props;
    const history = useHistory();

    const initialData = useMemo(() => {
        if (appraisal) {
            const formData: RatingFormValuesPairs = {};

            for (const key in appraisal.selfAppraisal.ratings) {
                formData[key] = {
                    rating: appraisal.selfAppraisal.ratings[key].rating,
                    comments: appraisal.selfAppraisal.ratings[key].comments,
                };
            }

            return formData;
        }

        return {};
    }, [appraisal]);

    const getCompetencyNames = () => {
        const competencies: string[] = [];

        for (const key in appraisal?.selfAppraisal.ratings) {
            competencies.push(key);
        }

        return competencies;
    };

    const saveSelfAppraisal = async (values: RatingFormValuesPairs, submit: boolean) => {
        if (appraisal) {
            for (const key in appraisal?.selfAppraisal.ratings) {
                appraisal.selfAppraisal.ratings[key].rating = values[key].rating;
                appraisal.selfAppraisal.ratings[key].comments = values[key].comments;
            }

            if (submit) {
                await ApiClient.appraisals.submitSelfAppraisal(appraisal, appraisal.selfAppraisal);
                window.alert("Your feedback has been submitted");
                history.push("../");
            } else {
                await ApiClient.appraisals.saveSelfAppraisal(appraisal, appraisal.selfAppraisal);
                window.alert("Draft has been saved");
            }
        }
    };

    if (appraisal?.relationship === AppraisalRelation.OwnAppraisal) {
        if (!appraisal.selfAppraisalComplete) {
            return (
                <>
                    <div className="cl-main__header">
                        <div className="cc-page-header">
                            <h1 className="cc-page-header__title c-heading +h1">
                                Appraisee Assessment for {appraisal?.employee.firstName}{" "}
                                {appraisal?.employee.lastName}
                            </h1>
                        </div>
                    </div>
                    <div className="cl-main__primary">
                        <ul className="u-marg-bottom-large">
                            <li>
                                <b>Quarter:</b> {appraisal.quarterYear}
                            </li>
                            <li>
                                <b>Role:</b> {appraisal.jobRoleName}
                            </li>
                            <li>
                                <b>Grade:</b> {appraisal.gradeName}
                            </li>
                            <li>
                                <b>Appraiser:</b> {appraisal?.appraiser.firstName}{" "}
                                {appraisal?.appraiser.lastName}
                            </li>
                        </ul>
                        {appraisal.roleDocumentUrl && (
                            <a
                                className="c-button u-marg-bottom"
                                href={appraisal.roleDocumentUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                View Competency Definitions
                            </a>
                        )}
                        {appraisal && (
                            <RatingForm
                                competencies={getCompetencyNames()}
                                data={initialData}
                                onSaveDraft={(values) => {
                                    saveSelfAppraisal(values, false);
                                }}
                                onSubmit={(values) => {
                                    saveSelfAppraisal(values, true);
                                }}
                                appraisalRelationship={appraisal.relationship}
                                additionalComments={appraisal.additionalComments}
                            />
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <div className="c-alert u-fill-warn">This self appraisal is already complete</div>
            );
        }
    } else {
        return <div className="c-alert u-fill-warn">You do not have access to this appraisal</div>;
    }
};
