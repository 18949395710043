import { Button, Switch } from "morse-react";
import { Route } from "react-router";
import { Link, RouteProps } from "react-router-dom";
import { useState } from "react";
import { ApiClient } from "../../api/ApiClient";
import { useFetchList } from "@gcdtech/acorn-crud-react";
import { Appraisal, AppraisalPermissions, ApprovalStatus, EmployeePermissions } from "types";
import { GuardedContainer } from "@gcdtech/acorn-react-core";
import { StaffList } from "./components/StaffList";
import { StartAppraisal } from "./StartAppraisal";
import dayjs from "dayjs";

export const AppraisalsOversight = (props: RouteProps) => {
    const [showActiveAppraisals, setShowActiveAppraisals] = useState<boolean>(true);
    const activeAppraisals = useFetchList(ApiClient.appraisals.listActiveAppraisals);
    const completedAppraisals = useFetchList(ApiClient.appraisals.listCompletedAppraisals);

    const onAppraisalSaved = () => {
        activeAppraisals.refetch();
    };

    const nudgeOne = (appraisalId: number) => {
        ApiClient.appraisals.nudgeOneAppraisal(appraisalId);
    };

    const abortAppraisal = async (appraisalID: number) => {
        await ApiClient.appraisals.abortAppraisal(appraisalID);
        activeAppraisals.refetch();
    };

    const peerFeedbackStatusDisplay = (appraisal: Appraisal) => {
        if (appraisal.peerFeedbackComplete) {
            return <span className="u-color-pos u-font-bold">Completed</span>;
        } else if (appraisal.numberPeerFeedBackComplete > 0) {
            return <span className="u-color-warn">In Progress</span>;
        } else {
            return <span className="u-translucent">Not Started</span>;
        }
    };

    const feedbackStatusDisplay = (inDraft: boolean, submitted: boolean) => {
        if (submitted) {
            return <span className="u-color-pos u-font-bold">Submitted</span>;
        } else if (inDraft) {
            return <span className="u-color-warn">In Draft</span>;
        } else {
            return <span className="u-translucent">Not Started</span>;
        }
    };

    const approvalStatusDisplay = (appraisal: Appraisal) => {
        const approvalStatus = appraisal.approvalStatus;
        if (approvalStatus === ApprovalStatus.Approved) {
            return <span className="u-color-pos u-font-bold">Approved</span>;
        } else if (approvalStatus === ApprovalStatus.Rejected) {
            return <span className="u-color-neg u-font-bold">Revision Requested</span>;
        } else if (appraisal.appraiserFeedbackComplete) {
            return <span className="u-color-warn">Received</span>;
        } else {
            return <span className="u-translucent">Not Received</span>;
        }
    };

    const displayAppraisal = (appraisal: Appraisal) => {
        const active = !appraisal.appraisalComplete;
        return (
            <tr key={appraisal.id}>
                <td className="c-table__cell +primary">
                    <Link
                        to={{
                            pathname: "/appraisals/" + appraisal.id,
                        }}
                    >
                        {appraisal.employee.firstName}
                    </Link>
                </td>
                <td>{appraisal.quarterYear}</td>
                <td>{dayjs(appraisal.appraisalStartDate).format("DD/MM/YY")}</td>

                <td>
                    {peerFeedbackStatusDisplay(appraisal)}{" "}
                    <span className="u-translucent">
                        ({appraisal.numberPeerFeedBackComplete}/{appraisal.totalNumberofPeers})
                    </span>
                </td>
                <td>
                    {feedbackStatusDisplay(
                        appraisal.selfAppraisalInDraft,
                        appraisal.selfAppraisalComplete
                    )}
                </td>
                <td>
                    {feedbackStatusDisplay(
                        appraisal.appraiserFeedbackInDraft,
                        appraisal.appraiserFeedbackComplete
                    )}
                </td>
                <td>{approvalStatusDisplay(appraisal)}</td>
                {!active && <td>{dayjs(appraisal.appraisalCompleteDate).format("DD/MM/YY")}</td>}
                {
                    //Only show nudge button if appraisal is active
                    active && (
                        <GuardedContainer requiredPermissions={[AppraisalPermissions.ReviewAll]}>
                            <td className="c-table__cell +tight">
                                <Button
                                    color={Button.Color.Quiet}
                                    onClick={() => nudgeOne(appraisal.id)}
                                >
                                    Nudge
                                </Button>
                            </td>
                        </GuardedContainer>
                    )
                }
                {
                    //Only show abort button if appraisal is active
                    active && (
                        <td className="c-table__cell +tight">
                            <Button
                                color={Button.Color.Negative}
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            "Are you sure you would like to abort the appraisal for " +
                                                appraisal.employee.firstName +
                                                "?"
                                        )
                                    ) {
                                        abortAppraisal(appraisal.id);
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </td>
                    )
                }
            </tr>
        );
    };

    const activeAppraisalsDom = activeAppraisals.items.map((appraisal) => {
        return displayAppraisal(appraisal);
    });

    const completedAppraisalsDom = completedAppraisals.items.map((appraisal) => {
        return displayAppraisal(appraisal);
    });

    return (
        <>
            <Route path="/appraisals/start" exact={true}>
                <StartAppraisal
                    onSaved={() => {
                        onAppraisalSaved();
                    }}
                />
            </Route>
            <div className="cl-main__header">
                <h1 className="c-heading +h1 u-marg-bottom">Appraisal Oversight</h1>
            </div>
            <div className="cl-main__primary">
                <div className="cc-panel +block">
                    <h2 className="cc-panel__title c-heading +h2">
                        {showActiveAppraisals ? "Active Appraisals" : "Completed Appraisals"}
                        <GuardedContainer requiredPermissions={[AppraisalPermissions.ReviewAll]}>
                            <Switch
                                className="u-marg-left"
                                slide
                                checked={showActiveAppraisals}
                                onChange={(e) => setShowActiveAppraisals(e.target.checked)}
                            />
                        </GuardedContainer>
                    </h2>
                    <div className="cc-panel__buttons">
                        <Link to="/appraisals/start" className="c-button">
                            Start a new Appraisal
                        </Link>
                    </div>
                    <div className="cc-panel__body">
                        <table id="activeAppraisalsTable" className="c-table +primary-list">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Quarter</th>
                                    <th>Appraisal Start Date</th>
                                    <th>Peer Feedback</th>
                                    <th>Appraisee Assessment</th>
                                    <th>Appraiser Assessment</th>
                                    <th>Director Approval</th>
                                    {showActiveAppraisals ? (
                                        <th />
                                    ) : (
                                        <th>Appraisal Complete Date</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {showActiveAppraisals
                                    ? activeAppraisalsDom
                                    : completedAppraisalsDom}
                            </tbody>
                        </table>
                    </div>
                </div>

                <GuardedContainer requiredPermissions={[EmployeePermissions.Manage]}>
                    <StaffList />
                </GuardedContainer>
            </div>
        </>
    );
};
