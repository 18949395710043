import { Button, Input, Label } from "morse-react";
import { Dialog } from "morse-react-dialogs";
import { Form, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { FormikMorseField } from "../../app-container/components/FormikMorseField";

export type FormValues = {
    name: string;
};

export type RoleDialogProps = {
    title: string;
    initialValues: FormValues;
    existingNames: string[];
    inputLabel: string;
    buttonName: string;
    onSubmit: (values: FormValues, setErrors: (errors: FormikErrors<FormValues>) => void) => void;
    onDismiss: () => void;
};

export const RoleDialog = (props: RoleDialogProps) => {
    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={async (values, { setErrors }) => {
                props.onSubmit(values, setErrors);
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .lowercase()
                    .required("This field is required")
                    .notOneOf(props.existingNames, "Name already exists"),
            })}
        >
            {({ handleSubmit, handleChange, values }) => {
                const handleClick = () => {
                    handleSubmit();
                };

                return (
                    <Dialog
                        allowDismiss
                        title={props.title}
                        onDismissed={props.onDismiss}
                        closeText="Close"
                        buttons={[
                            <Button key="submit" onClick={handleClick}>
                                {props.buttonName}
                            </Button>,
                        ]}
                    >
                        <Form>
                            <FormikMorseField className="u-marg-bottom" field="name">
                                <Label>{props.inputLabel}</Label>
                                <Input
                                    fill
                                    type="text"
                                    className="fill"
                                    value={values.name}
                                    onChange={handleChange("name")}
                                />
                            </FormikMorseField>
                        </Form>
                    </Dialog>
                );
            }}
        </Formik>
    );
};
