import { Select, Button } from "morse-react";
import { Dialog } from "morse-react-dialogs";
import { useEffect, useState } from "react";
import { Route, useHistory } from "react-router";
import { AppraiserPermissions, RatingResponce } from "types";
import { EmployeeStatus } from "../../types";
import { TruthPill } from "../../app-container/components/TruthPill";
import { Link } from "react-router-dom";
import { ApiClient } from "../../api/ApiClient";
import { useFetch } from "@gcdtech/acorn-react-core";
import { GuardedContainer } from "@gcdtech/acorn-react-core";

export const EmployeeDashboard = () => {
    const [employee, setEmployee] = useState<EmployeeStatus>();
    const [selectedRole, setSelectedRole] = useState<string>();

    const { item: previousAppraisals } = useFetch(ApiClient.employees.getPreviousAppraisal);

    useEffect(() => {
        ApiClient.employees.getEmployeeStatus().then((response) => {
            setEmployee(response);
            setSelectedRole(response.role);
        });
    }, []);

    const pastRoles = employee?.pastRoles.map((role) => ({
        item: role,
        label: role,
        value: role,
    }));

    const peers = employee?.peersGivingFeedBack.map((peer) => {
        return <li key={peer}>{peer}</li>;
    });

    const displayFeedbackStatus = (inDraft: boolean, submitted: boolean) => {
        if (submitted) {
            return <span className="u-color-pos">Submitted</span>;
        } else if (inDraft) {
            return <span className="u-color-warn">In Draft</span>;
        } else {
            return <span className="u-color-warn">Not Started</span>;
        }
    };

    const displayAppraisalStatus = () => {
        if (employee?.currentAppraisal != null) {
            const urlCurrentAppraisal: string = "/appraisals/" + employee?.currentAppraisal.id;
            return (
                <div className="cc-panel +div +block">
                    <h2 className="cc-panel__title c-heading +h2">
                        <Link
                            to={{
                                pathname: urlCurrentAppraisal,
                            }}
                        >
                            {"Ongoing Appraisal for " + employee.currentAppraisal.quarterYear}
                        </Link>
                    </h2>
                    <div className="cc-panel__body">
                        <div className="cc-panel +1b +x-tight">
                            <h3 className="c-heading +h3" key="PeerFeedBack">
                                {" "}
                                Peers giving Feedback:{" "}
                                <TruthPill value={employee.currentAppraisal.peerFeedbackComplete} />
                            </h3>
                            <ul>{peers}</ul>
                        </div>
                        <div className="cc-panel +1b +x-tight">
                            <h3 className="c-heading +h3">
                                Appraisee Assessment:{" "}
                                {displayFeedbackStatus(
                                    employee.currentAppraisal.selfAppraisalInDraft,
                                    employee.currentAppraisal.selfAppraisalComplete
                                )}
                            </h3>
                            {!employee.currentAppraisal.selfAppraisalComplete && (
                                <div className="cc-panel__button-list">
                                    <Link
                                        className="c-button +quiet +small"
                                        to={{
                                            pathname: urlCurrentAppraisal + "/self-appraisal",
                                        }}
                                    >
                                        Complete Assessment
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="cc-panel +1b +x-tight">
                            <h3 className="c-heading +h3" key={employee.appraiser}>
                                {" "}
                                Appraiser: {employee.appraiser}
                            </h3>
                            <ul>
                                <li>
                                    Appraiser Feedback:{" "}
                                    <TruthPill
                                        value={employee.currentAppraisal.appraiserFeedbackComplete}
                                    />
                                </li>
                                <li>
                                    Takeaways:{" "}
                                    <TruthPill
                                        value={employee.currentAppraisal.takeAway ? true : false}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="cc-panel +div +block">
                    <h2 className="cc-panel__title c-heading +h2">Ongoing Appraisals</h2>
                    <div className="cc-panel__body">
                        <div className="cc-panel +2 +x-tight">You have no ongoing Appraisals</div>
                    </div>
                </div>
            );
        }
    };

    const displayRequestedLineManagerFeedback = () => {
        if (employee?.requestedLineMangerFeedback) {
            if (employee?.requestedLineMangerFeedback.length > 0) {
                const feedBackRequests = employee?.requestedLineMangerFeedback.map(
                    (feedBackRequest) => {
                        const url: string = "/appraisals/" + feedBackRequest.appraisalId;
                        const urlFeedback: string = url + "/line-manger-appraisal";
                        return (
                            <div
                                className="cc-panel +1b +x-tight"
                                key={feedBackRequest.appraisalId}
                            >
                                <h3 className="c-heading +h3">
                                    <Link
                                        to={{
                                            pathname: url,
                                        }}
                                    >
                                        {feedBackRequest.appraiseeName}
                                    </Link>
                                </h3>
                                <div className="cc-panel__button-list">
                                    {feedBackRequest.selfAppraisalComplete &&
                                        !feedBackRequest.appraiserFeedbackComplete && (
                                            <Link
                                                className="c-button +small"
                                                to={{
                                                    pathname: urlFeedback,
                                                }}
                                            >
                                                Appraiser Assessment
                                            </Link>
                                        )}
                                    <Link
                                        className="c-button +quiet +small"
                                        to={{
                                            pathname: url,
                                        }}
                                    >
                                        View Progress
                                    </Link>
                                </div>
                            </div>
                        );
                    }
                );
                return <div>{feedBackRequests}</div>;
            }
        }
        return <div className="cc-panel +2 +x-tight">You have no requests for feedback</div>;
    };

    const displayRequestedPeerFeedBack = () => {
        if (employee?.requestedPeerFeedBack !== undefined) {
            const feedBackRequests = employee?.requestedPeerFeedBack.map((feedBackRequest) => {
                const url: string = "/appraisals/" + feedBackRequest.appraisalId + "/peer-feedback";
                return (
                    <div className="cc-panel +1b +tighter" key={feedBackRequest.appraisalId}>
                        <h3 className="c-heading +h3">
                            <Link
                                to={{
                                    pathname: url,
                                }}
                            >
                                {feedBackRequest.peer}
                            </Link>
                        </h3>
                        <div className="cc-panel__button-list">
                            <Link
                                className="c-button +small"
                                to={{
                                    pathname: url,
                                }}
                            >
                                Complete Feedback
                            </Link>
                        </div>
                    </div>
                );
            });
            return <div>{feedBackRequests}</div>;
        }
        return <div className="cc-panel +2 +tighter">You have no requests for feedback</div>;
    };

    const findReleventCompetencies = (
        appraisals: Array<{
            quarterYear: string;
            grade: string;
            role: string;
            ratings: RatingResponce[];
        }> = []
    ) => {
        const releventCompetencies: string[] = [];

        for (let n = 0; n < appraisals.length; n++) {
            const appraisal = appraisals[n];
            for (let i = 0; i < appraisal.ratings.length; i++) {
                const competency = appraisal.ratings[i].competency;
                if (!releventCompetencies.includes(competency)) {
                    releventCompetencies.push(competency);
                }
            }
        }
        return releventCompetencies;
    };

    const findReleventAppraisals = () => {
        const releventAppraisals: Array<{
            appraisalId: number;
            quarterYear: string;
            grade: string;
            role: string;
            level: number;
            ratings: RatingResponce[];
        }> = [];
        if (previousAppraisals?.appraisals !== undefined) {
            for (let i = 0; i < previousAppraisals?.appraisals.length; i++) {
                if (previousAppraisals.appraisals[i].role === selectedRole) {
                    releventAppraisals.push(previousAppraisals.appraisals[i]);
                }
            }
            return releventAppraisals;
        } else {
            return releventAppraisals;
        }
    };

    const DisplayPreviousAppraisals = () => {
        const releventAppraisals = findReleventAppraisals();
        if (releventAppraisals.length > 0 && pastRoles) {
            const competencies = findReleventCompetencies(releventAppraisals);

            const compitencyTitles = competencies.map((competency) => {
                return <th key={competency}>{competency}</th>;
            });

            const previousAppraisalForDisplay = releventAppraisals.map((appraisal) => {
                const sortedRatings = new Array(competencies.length);
                sortedRatings.fill(undefined);
                for (let i = 0; i < competencies.length; i++) {
                    for (let n = 0; n < appraisal.ratings.length; n++) {
                        if (competencies[i] === appraisal.ratings[n].competency) {
                            sortedRatings[i] = appraisal.ratings[n];
                        }
                    }
                }

                const ratings = sortedRatings.map((rating) => {
                    if (rating !== undefined) {
                        return <td key={rating.rating}>{rating.rating}</td>;
                    } else {
                        return <td key="Empty competency">N/A</td>;
                    }
                });

                const url: string = "/appraisals/" + appraisal.appraisalId;
                return (
                    <tr key={appraisal.quarterYear}>
                        <td className="c-table__cell +primary">
                            <Link
                                to={{
                                    pathname: url,
                                }}
                            >
                                {appraisal.quarterYear}
                            </Link>
                        </td>
                        <td>
                            {appraisal.grade !== appraisal.role && appraisal.grade} {appraisal.role}
                        </td>
                        <td>{appraisal.level}</td>
                        {ratings}
                    </tr>
                );
            });

            return (
                <div className="cc-panel +block">
                    <h2 className="cc-panel__title c-heading +h2 u-marg-bottom">
                        My previous Appraisals
                    </h2>
                    <div className="cc-panel__buttons">
                        {pastRoles?.length > 1 && (
                            <Select<string, string>
                                placeholderText={selectedRole}
                                options={pastRoles}
                                onChange={(value) => {
                                    setSelectedRole(value);
                                }}
                            />
                        )}
                    </div>
                    <div className="cc-panel__body">
                        <div className="c-table__container">
                            <table className="c-table +primary-list">
                                <thead>
                                    <tr>
                                        <th>Quarter</th>
                                        <th>Role</th>
                                        <th>Level</th>
                                        {compitencyTitles}
                                    </tr>
                                </thead>
                                <tbody>{previousAppraisalForDisplay}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className="cc-panel +2 +tighter">You have not yet had any Appraisals.</div>;
        }
    };

    const history = useHistory();

    const saveUser = () => {
        //do your savey stuff
        history.push("/");
    };

    return (
        <Route path="/" exact={true}>
            <Route path="/add" exact={true}>
                <Dialog
                    allowDismiss
                    title="A lovely dialog"
                    onDismissed={() => {
                        history.push("/");
                    }}
                    buttons={[
                        <Button key="save" onClick={saveUser}>
                            Save
                        </Button>,
                    ]}
                >
                    <p>This is the dialog body</p>
                </Dialog>
            </Route>
            <div className="cl-main__header">
                <div className="cc-page-header">
                    <h1 className="cc-page-header__title c-heading +h1">
                        My Professional Development Record
                    </h1>
                    {employee && (
                        <div className="cc-page-header__actions">
                            {/*employee.employeeName*/}
                            <div className="u-font-bold u-font-large">
                                {employee.grade !== employee.role && employee.grade} {employee.role}{" "}
                                (Level {employee.level})
                            </div>
                            {employee.lastPromoted && (
                                <div className="u-font-bold u-font-large">
                                    As of {employee.lastPromoted}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            {employee && (
                <>
                    <div className="cl-main__primary">
                        {/* Do we need to know their name if it's their admin area? */}
                        {/*<p key={employee.employeeName}>*/}
                        {/*    {employee.employeeName} {employee.grade} {employee.role}*/}
                        {/*</p>*/}
                        <DisplayPreviousAppraisals />
                    </div>
                    <div className="cl-main__secondary">{displayAppraisalStatus()}</div>
                    <div className="cl-main__third">
                        <div className="cc-panel">
                            <h2 className="c-heading +h2">Requested Feedback for Peers</h2>
                            {displayRequestedPeerFeedBack()}
                        </div>
                        <GuardedContainer requiredPermissions={[AppraiserPermissions.Manage]}>
                            <div className="cc-panel">
                                <h2 className="c-heading +h2">Requested Appraiser Assessment</h2>
                                {displayRequestedLineManagerFeedback()}
                            </div>
                        </GuardedContainer>
                    </div>
                </>
            )}
        </Route>
    );
};
