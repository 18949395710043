import { AppraisalsApi } from "./AppraisalsApi";
import { EmployeesApi } from "./EmployeesApi";
import { RolesApi } from "./RolesApi";

export class ApiClient {
    static appraisals: AppraisalsApi;
    static employees: EmployeesApi;
    static roles: RolesApi;

    static initialise() {
        ApiClient.appraisals = AppraisalsApi.get();
        ApiClient.employees = EmployeesApi.get();
        ApiClient.roles = RolesApi.get();
    }
}
