import { Button, Input, Label, Select } from "morse-react";
import { useHistory } from "react-router";
import { useState } from "react";
import { Dialog } from "morse-react-dialogs";
import { useFormikContext } from "formik";
import { FormikMorseField } from "../../app-container/components/FormikMorseField";
import { ApiClient } from "../../api/ApiClient";
import { useFetch } from "@gcdtech/acorn-react-core";
import { CrudItemComponentProps } from "../../../../acorn/packages/crud/acorn-crud-react/build";
import { Employee } from "../../../../types/build";
import { FormikDate } from "./components/FormikDate";

export type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    roleId: number;
    gradeId: number;
    level: number;
    lastPromoted: Date;
    userRole: string;
};

export type EmployeeDialogProps = CrudItemComponentProps<Employee> & {
    //onSubmit: (values: FormValues, setErrors: (errors: FormikErrors<FormValues>) => void) => void;
};

export const EmployeeDialog = (props: EmployeeDialogProps) => {
    const history = useHistory();
    const roles = useFetch(ApiClient.appraisals.getRoles);
    const [chosenRole, setChosenRole] = useState<number>(props.item?.roleId ?? 0);
    const userRoleOptions = [
        { value: "Employee", label: "Employee" },
        { value: "Appraiser", label: "Appraiser" },
        { value: "HR Manager", label: "HR Manager" },
        { value: "Director", label: "Director" },
    ];

    const formik = useFormikContext<Record<string, string | number>>();

    return (
        <Dialog
            allowDismiss
            title={props.item.id ? "Edit Employee" : "Create New Employee"}
            onDismissed={() => {
                history.push("/appraisals");
            }}
            buttons={[
                <Button
                    key="save"
                    busy={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    onClick={() => {
                        return formik.handleSubmit();
                    }}
                >
                    Save Employee
                </Button>,
            ]}
        >
            {roles.item && (
                <>
                    <FormikMorseField field="firstName">
                        <Label>First Name</Label>
                        <Input
                            fill
                            type="text"
                            value={formik.values["firstName"]}
                            onChange={formik.handleChange("firstName")}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="lastName">
                        <Label>Last Name</Label>
                        <Input
                            fill
                            type="text"
                            value={formik.values["lastName"]}
                            onChange={formik.handleChange("lastName")}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="email">
                        <Label>Email</Label>
                        <Input
                            fill
                            type="text"
                            value={formik.values["email"]}
                            onChange={formik.handleChange("email")}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="roleId">
                        <Label>Role</Label>
                        <Select
                            fill
                            options={roles.item.map((role) => ({
                                value: role.id,
                                label: role.name,
                            }))}
                            value={formik.values["roleId"] as number}
                            onChange={(chosenRole) => {
                                setChosenRole(chosenRole ?? 0);
                                formik.setValues({
                                    ...formik.values,
                                    roleId: chosenRole ?? 0,
                                    gradeId: 0,
                                });
                            }}
                        />
                    </FormikMorseField>
                    <FormikMorseField field="gradeId">
                        <Label>Grade</Label>
                        <Select
                            fill
                            options={
                                roles.item
                                    .find((role) => role.id === chosenRole)
                                    ?.grades.map((grade) => ({
                                        value: grade.id,
                                        label: grade.name,
                                    })) ?? []
                            }
                            clearable={true}
                            value={formik.values["gradeId"] as number}
                            onChange={(chosenGrade) => {
                                formik.setValues({
                                    ...formik.values,
                                    gradeId: chosenGrade ?? 0,
                                });
                            }}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="level">
                        <Label>Level</Label>
                        <Input
                            fill
                            type="number"
                            value={formik.values["level"]}
                            onChange={formik.handleChange("level")}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="lastPromoted">
                        <Label>Last promotion on</Label>
                        <FormikDate
                            value={formik.values["lastPromoted"]}
                            onChange={formik.handleChange("lastPromoted")}
                        />
                    </FormikMorseField>

                    <FormikMorseField field="userRole">
                        <Label>User Role</Label>
                        <Select
                            fill
                            options={userRoleOptions}
                            value={formik.values["userRole"]?.toString()}
                            onChange={(chosenUserRole) => {
                                formik.setValues({
                                    ...formik.values,
                                    userRole: chosenUserRole ?? "",
                                });
                            }}
                        />
                    </FormikMorseField>
                </>
            )}
        </Dialog>
    );
};
