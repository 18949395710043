import React, { useState } from "react";
import { Route, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Button, Input } from "morse-react";
import { JobRole } from "types";
import { RoleDialog } from "./RoleDialog";
import { ApiClient } from "../../api/ApiClient";

type EditRoleProps = {
    existingRole: boolean;
    roleData?: JobRole;
    onSaved: () => void;
};

type EditGrade = {
    id?: number;
    name: string;
    assigned: boolean;
    edited: boolean; //Has the name been edited (only relevant for existing grades)
};

type EditCompetency = {
    id: (number | undefined)[];
    name: string;
    existing: boolean[]; //Does the competency exist in DB for each grade
    edited: boolean; //Has the name been edited (only relevant for existing competencies)
};

export const defaultGrades = ["Junior", "Intermediate", "Senior", "Principal"];
export const defaultCompetencies = [
    "Work Productively",
    "Work Collaboratively",
    "Work Skillfully",
    "Work Professionally",
];

export const EditRole = (props: EditRoleProps) => {
    const history = useHistory();
    const currentRole = props.roleData;

    let url = props.existingRole ? "/roles/" + currentRole?.id : "/roles/new/" + currentRole?.name;

    const getCompetencies = () => {
        const competencies: EditCompetency[] = [];
        if (props.existingRole) {
            currentRole?.grades.forEach((grade, gradeIndex) => {
                grade.competencies.forEach((competency) => {
                    const existingCompetency = competencies.find((c) => c.name === competency.name);
                    //Have we already found this competency in another grade?
                    if (existingCompetency) {
                        existingCompetency.id[gradeIndex] = competency.id;
                        existingCompetency.existing[gradeIndex] = true;
                    } else {
                        const id = Array(currentRole.grades.length).fill(undefined);
                        id[gradeIndex] = competency.id;
                        const existing = Array(currentRole.grades.length).fill(false);
                        existing[gradeIndex] = true;
                        competencies.push({
                            id: id,
                            name: competency.name,
                            existing: existing,
                            edited: false,
                        });
                    }
                });
            });
        } else {
            defaultCompetencies.forEach((competency) => {
                const id = Array(defaultGrades.length).fill(undefined);
                const existing = Array(defaultGrades.length).fill(false);
                competencies.push({ id: id, name: competency, existing: existing, edited: false });
            });
        }

        return competencies;
    };

    const getGrades = () => {
        return currentRole && props.existingRole
            ? currentRole.grades.map((grade) => {
                  return { id: grade.id, name: grade.name, assigned: true, edited: false };
              })
            : defaultGrades.map((grade) => {
                  return { name: grade, assigned: true, edited: false };
              });
    };

    const getCompetencyMatrix = (grades: EditGrade[], competencies: EditCompetency[]) => {
        const matrix: boolean[][] = [];
        for (let i = 0; i < grades.length; i++) {
            matrix[i] = [];
            const currentGrade = grades[i];
            const gradeObj = currentRole?.grades.find((grade) => grade.name === currentGrade.name);
            for (let j = 0; j < competencies.length; j++) {
                const competency = competencies[j];
                matrix[i][j] = gradeObj && competency.existing[i] ? true : false;
            }
        }
        return matrix;
    };

    const [currentGrades, setCurrentGrades] = useState<EditGrade[]>(() => getGrades());
    const [currentCompetencies, setCurrentCompetencies] = useState<EditCompetency[]>(() =>
        getCompetencies()
    );
    const [competencyMatrix, setCompetencyMatrix] = useState<boolean[][]>(() =>
        getCompetencyMatrix(currentGrades, currentCompetencies)
    );

    const updateGrade = (event: React.ChangeEvent<HTMLInputElement>, gradeIndex: number) => {
        const grades = currentGrades.slice();
        grades[gradeIndex] = { ...grades[gradeIndex], assigned: event.target.checked };
        setCurrentGrades(grades);

        //Clear all competencies for grade when deselected
        if (!event.target.checked) {
            const matrix = competencyMatrix.slice();
            matrix[gradeIndex] = matrix[gradeIndex].map(() => {
                return false;
            });
            setCompetencyMatrix(matrix);
        }
    };

    const updateCompetency = (
        event: React.ChangeEvent<HTMLInputElement>,
        gradeIndex: number,
        competencyIndex: number
    ) => {
        const matrix = competencyMatrix.slice();
        matrix[gradeIndex][competencyIndex] = event.target.checked;
        setCompetencyMatrix(matrix);
    };

    const displayCells = (competencyIndex: number) => {
        return currentGrades.map((grade, gradeIndex) => {
            return (
                <td key={currentRole?.id + "-" + competencyIndex + "-" + gradeIndex}>
                    <Input
                        checked={competencyMatrix[gradeIndex][competencyIndex]}
                        //Disable checkbox if grade has been deselected
                        //or competency is already assigned to this grade in DB
                        disabled={
                            !currentGrades[gradeIndex].assigned ||
                            currentCompetencies[competencyIndex].existing[gradeIndex]
                        }
                        type="checkbox"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            updateCompetency(event, gradeIndex, competencyIndex)
                        }
                    />
                </td>
            );
        });
    };

    const competencyList = currentCompetencies.map((competency, index) => {
        return (
            <tr key={index}>
                <td>
                    <Link to={url + "/edit-competency/" + index}>{competency.name}</Link>
                </td>
                {displayCells(index)}
            </tr>
        );
    });

    const gradeList = currentGrades.map((grade, index) => {
        return (
            <th key={currentRole?.id + "-" + index}>
                <Input
                    defaultChecked
                    //Disable checkbox if grade already exists for this role in DB
                    disabled={grade.id !== undefined}
                    type="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        updateGrade(event, index)
                    }
                />
                <Link className="u-marg-left" to={url + "/edit-grade/" + index}>
                    {grade.name}
                </Link>
            </th>
        );
    });

    const addGrade = (name: string) => {
        competencyMatrix[0]
            ? setCompetencyMatrix(
                  competencyMatrix.concat([Array(competencyMatrix[0].length).fill(false)])
              )
            : //Initialise array if no competencies yet
              setCompetencyMatrix(competencyMatrix.concat([Array(0)]));

        setCurrentGrades(currentGrades.concat({ name: name, assigned: true, edited: false }));
        setCurrentCompetencies(
            currentCompetencies.map((competency) => {
                return {
                    ...competency,
                    id: competency.id.concat(undefined),
                    existing: competency.existing.concat(false),
                };
            })
        );
    };

    const editGradeName = (index: number, name: string) => {
        const gradeList = currentGrades.slice();
        const grade = gradeList[index];
        gradeList[index] = { ...grade, name: name, edited: true };
        setCurrentGrades(gradeList);
    };

    const addCompetency = (name: string) => {
        setCompetencyMatrix(
            competencyMatrix.map((grade) => {
                return grade.concat(false);
            })
        );
        setCurrentCompetencies(
            currentCompetencies.concat({
                id: Array(currentGrades.length).fill(undefined),
                name: name,
                existing: Array(currentGrades.length).fill(false),
                edited: false,
            })
        );
    };

    const editCompetencyName = (index: number, name: string) => {
        const competencyList = currentCompetencies.slice();
        const competency = competencyList[index];
        competencyList[index] = { ...competency, name: name, edited: true };
        setCurrentCompetencies(competencyList);
    };

    const createRole = async () => {
        if (currentRole) {
            const newGrades = [];
            for (let i = 0; i < currentGrades.length; i++) {
                const grade = currentGrades[i];
                if (grade.assigned) {
                    const competencies = [];
                    for (let j = 0; j < currentCompetencies.length; j++) {
                        const competency = currentCompetencies[j];
                        if (competencyMatrix[i][j]) {
                            competencies.push(competency);
                        }
                    }
                    if (competencies.length > 0) {
                        const gradeObj = {
                            name: grade.name,
                            competencies: competencies.map((competency) => competency.name),
                        };
                        newGrades.push(gradeObj);
                    } else {
                        window.alert("You cannot add a grade with no competencies");
                        return;
                    }
                }
            }

            if (newGrades.length === 0) {
                window.alert("You must add a grade to this role");
            } else {
                const newRole = {
                    name: currentRole.name,
                    documentUrl: currentRole.documentUrl,
                    grades: newGrades,
                };
                try {
                    await ApiClient.roles.createRole(newRole);
                    window.alert("New role has been saved");
                    props.onSaved();
                } catch (e) {
                    //TODO: show specific error details
                    window.alert("Unable to save new role");
                }
            }
        }
    };

    const updateRole = async () => {
        if (currentRole) {
            const updatedGrades = [];
            for (let i = 0; i < currentGrades.length; i++) {
                const grade = currentGrades[i];
                if (grade.assigned) {
                    const updatedCompetencies = [];
                    for (let j = 0; j < currentCompetencies.length; j++) {
                        const competency = currentCompetencies[j];
                        if (
                            competencyMatrix[i][j] &&
                            (!competency.existing[i] || competency.edited)
                        ) {
                            const competencyObj = { id: competency.id[i], name: competency.name };
                            updatedCompetencies.push(competencyObj);
                        }
                    }
                    const gradeObj = {
                        id: grade.id,
                        name: grade.name,
                        competencies: updatedCompetencies,
                    };
                    if (updatedCompetencies.length > 0 || (grade.edited && grade.id)) {
                        updatedGrades.push(gradeObj);
                    }
                }
            }
            const updateRole = {
                id: currentRole.id,
                name: currentRole.name,
                documentUrl: currentRole.documentUrl,
                grades: updatedGrades,
            };
            try {
                await ApiClient.roles.updateRole(updateRole);
                window.alert("Role changes have been saved");
                props.onSaved();
            } catch (e) {
                //TODO: show specific error details
                window.alert("Unable to save role changes");
            }
        }
    };

    const displayDialogs = () => {
        return (
            <div>
                <Route path={url + "/link-document"} exact={true}>
                    {currentRole && (
                        <RoleDialog
                            title="Link Role Document"
                            initialValues={{ name: currentRole.documentUrl ?? "" }}
                            existingNames={[]}
                            inputLabel="Document Url"
                            buttonName="Confirm"
                            onSubmit={(values) => {
                                currentRole.documentUrl = values.name;
                                history.push(url);
                            }}
                            onDismiss={() => {
                                history.push(url);
                            }}
                        />
                    )}
                </Route>

                <Route path={url + "/edit-name"} exact={true}>
                    {currentRole && (
                        <RoleDialog
                            title="Edit Role"
                            initialValues={{ name: currentRole.name }}
                            existingNames={[]}
                            inputLabel="Name"
                            buttonName="Confirm"
                            onSubmit={(values) => {
                                currentRole.name = values.name;
                                if (!props.existingRole) {
                                    url = "/roles/new/" + values.name;
                                }
                                history.push(url);
                            }}
                            onDismiss={() => {
                                history.push(url);
                            }}
                        />
                    )}
                </Route>

                <Route path={url + "/add-grade"} exact={true}>
                    <RoleDialog
                        title="Add New Grade"
                        initialValues={{ name: "" }}
                        existingNames={currentGrades.map((grade) => grade.name.toLowerCase())}
                        inputLabel="Name"
                        buttonName="Add"
                        onSubmit={(values) => {
                            addGrade(values.name);
                            history.push(url);
                        }}
                        onDismiss={() => {
                            history.push(url);
                        }}
                    />
                </Route>

                <Route
                    path={url + "/edit-grade/:index"}
                    exact={true}
                    render={({ match }) => {
                        const index = Number(match.params.index);
                        const grade = currentGrades[index];
                        return (
                            <RoleDialog
                                title="Edit Grade"
                                initialValues={{ name: grade.name }}
                                existingNames={currentGrades
                                    .map((grade) => grade.name.toLowerCase())
                                    .filter((g) => g !== grade.name.toLowerCase())}
                                inputLabel="Name"
                                buttonName="Confirm"
                                onSubmit={(values) => {
                                    editGradeName(index, values.name);
                                    history.push(url);
                                }}
                                onDismiss={() => {
                                    history.push(url);
                                }}
                            />
                        );
                    }}
                />

                <Route path={url + "/add-competency"} exact={true}>
                    <RoleDialog
                        title="Add New Competency"
                        initialValues={{ name: "" }}
                        existingNames={currentCompetencies.map((competency) =>
                            competency.name.toLowerCase()
                        )}
                        inputLabel="Name"
                        buttonName="Add"
                        onSubmit={(values) => {
                            addCompetency(values.name);
                            history.push(url);
                        }}
                        onDismiss={() => {
                            history.push(url);
                        }}
                    />
                </Route>

                <Route
                    path={url + "/edit-competency/:index"}
                    exact={true}
                    render={({ match }) => {
                        const index = Number(match.params.index);
                        const competency = currentCompetencies[index];
                        return (
                            <RoleDialog
                                title="Edit Competency"
                                initialValues={{ name: competency.name }}
                                existingNames={currentCompetencies
                                    .map((competency) => competency.name.toLowerCase())
                                    .filter((c) => c !== competency.name.toLowerCase())}
                                inputLabel="Name"
                                buttonName="Confirm"
                                onSubmit={(values) => {
                                    editCompetencyName(index, values.name);
                                    history.push(url);
                                }}
                                onDismiss={() => {
                                    history.push(url);
                                }}
                            />
                        );
                    }}
                />
            </div>
        );
    };

    return (
        <>
            {currentRole && (
                <>
                    {displayDialogs()}
                    <div className="cc-panel +block">
                        <h2 className="c-heading +h2 cc-panel__title">
                            <Link to={url + "/edit-name"}>{currentRole?.name}</Link>
                        </h2>
                        <div className="cc-panel__buttons">
                            <Link to={url + "/link-document"} className="c-button +quiet">
                                Link Role Document
                            </Link>
                            <Link to={url + "/add-grade"} className="c-button +quiet">
                                Add New Grade
                            </Link>
                            {currentGrades.length > 0 && (
                                <Link to={url + "/add-competency"} className="c-button +quiet">
                                    Add New Competency
                                </Link>
                            )}
                            <Button onClick={props.existingRole ? updateRole : createRole}>
                                Save
                            </Button>
                        </div>
                        <div className="cc-panel__body">
                            <table className="c-table +primary-list">
                                <thead>
                                    <tr>
                                        <th />
                                        {gradeList}
                                    </tr>
                                </thead>
                                <tbody>{competencyList}</tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
