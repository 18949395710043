import { Button, Input, Label, Select } from "morse-react";
import { ChangeEvent } from "react";
import { Dialog } from "morse-react-dialogs";
import { Form, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { FormikMorseField } from "../../../app-container/components/FormikMorseField";
import { ApiClient } from "../../../api/ApiClient";
import { useFetch } from "@gcdtech/acorn-react-core";
import { AppraisalSystemFetchingSuspense } from "../../../app-container/components/suspense/AppraisalSystemFetchingSuspense";
import { NextRole } from "types";

export type RoleChangeDialogProps = {
    initialValues: NextRole;
    onSubmit: (values: NextRole, setErrors: (errors: FormikErrors<NextRole>) => void) => void;
    onDismiss: () => void;
};

export const RoleChangeDialog = (props: RoleChangeDialogProps) => {
    const roles = useFetch(ApiClient.appraisals.getRoles);

    return (
        <AppraisalSystemFetchingSuspense itemFetchingStatus={roles}>
            {roles.fetched && (
                <Formik
                    initialValues={props.initialValues}
                    onSubmit={async (values, { setErrors }) => {
                        props.onSubmit(values, setErrors);
                    }}
                    validationSchema={Yup.object().shape({
                        id: Yup.number()
                            .min(1, "You must select a role")
                            .required("You must select a role"),
                        grade: Yup.object().shape({
                            id: Yup.number()
                                .min(1, "You must select a grade")
                                .required("You must select a grade"),
                            level: Yup.number()
                                .min(1, "Level must be at least 1")
                                .required("You must enter a level"),
                        }),
                    })}
                >
                    {({ handleSubmit, values, setValues }) => {
                        const handleClick = () => {
                            handleSubmit();
                        };

                        return (
                            <Dialog
                                allowDismiss
                                title="Change Employee Role"
                                onDismissed={props.onDismiss}
                                buttons={[
                                    <Button key="save" onClick={handleClick}>
                                        Save
                                    </Button>,
                                ]}
                            >
                                <Form>
                                    <FormikMorseField field="id">
                                        <Label>Role</Label>
                                        <Select
                                            options={roles.item.map((role) => ({
                                                value: role.id,
                                                label: role.name,
                                            }))}
                                            value={values.id}
                                            onChange={(chosenRole) => {
                                                setValues({
                                                    id: chosenRole ?? 0,
                                                    name:
                                                        chosenRole !== undefined
                                                            ? roles.item.find(
                                                                  (r) => r.id === chosenRole
                                                              )?.name
                                                            : "",
                                                    grade: {
                                                        id: 0,
                                                        name: "",
                                                        level: values.grade?.level ?? 1,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormikMorseField>

                                    <FormikMorseField field="grade.id">
                                        <Label>Grade</Label>
                                        <Select
                                            options={
                                                roles.item
                                                    .find((role) => role.id === values.id)
                                                    ?.grades.map((grade) => ({
                                                        value: grade.id,
                                                        label: grade.name,
                                                    })) ?? []
                                            }
                                            clearable={true}
                                            value={values.grade?.id}
                                            onChange={(chosenGrade) => {
                                                setValues({
                                                    ...values,
                                                    grade: {
                                                        id: chosenGrade ?? 0,
                                                        name:
                                                            chosenGrade !== undefined
                                                                ? roles.item
                                                                      .find(
                                                                          (r) => r.id === values.id
                                                                      )
                                                                      ?.grades.find(
                                                                          (g) =>
                                                                              g.id === chosenGrade
                                                                      )?.name
                                                                : "",
                                                        level: values.grade?.level ?? 1,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormikMorseField>

                                    <FormikMorseField field="grade.level">
                                        <Label>Level</Label>
                                        <Input
                                            type="number"
                                            value={values.grade?.level}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setValues({
                                                    ...values,
                                                    grade: {
                                                        ...values.grade,
                                                        level: e.target.valueAsNumber,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormikMorseField>
                                </Form>
                            </Dialog>
                        );
                    }}
                </Formik>
            )}
        </AppraisalSystemFetchingSuspense>
    );
};
