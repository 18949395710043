import {
    Appraisal,
    ApprovalStatus,
    CreateAppraisalDto,
    feedBackRequestResponce,
    PeerFeedBackDto,
    PreviousAppraisalsResponseDto,
    JobRoles,
    FeedBackAppraisal,
} from "types";
import { Api } from "@gcdtech/acorn-react-core";
import {
    FetchListResponse,
    ListCriteria,
    ListRequest,
} from "../../../acorn/packages/core/acorn-common-types/build";

export class AppraisalsApi extends Api {
    listActiveAppraisals = async (
        request: ListRequest<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>
    ): Promise<FetchListResponse<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>> => {
        return (
            await this.api.get<
                FetchListResponse<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>
            >("/appraisal/current")
        ).data;
    };

    listCompletedAppraisals = async (
        request: ListRequest<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>
    ): Promise<FetchListResponse<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>> => {
        return (
            await this.api.get<
                FetchListResponse<Appraisal, ListCriteria<Appraisal>, keyof Appraisal>
            >("/appraisal/complete")
        ).data;
    };

    async createAppraisal(appraisalData: CreateAppraisalDto): Promise<Appraisal> {
        return (await this.api.post<Appraisal>("/appraisal", appraisalData)).data;
    }

    getRoles = async (): Promise<JobRoles> => {
        return (await this.api.get<JobRoles>("/roles")).data;
    };

    async Approval(appraisalId: string): Promise<Appraisal> {
        return (await this.api.post<Appraisal>("/appraisal/" + appraisalId + "/director_approve"))
            .data;
    }

    getAppraisal = async (appraisalId: string): Promise<Appraisal> => {
        return (await this.api.get<Appraisal>("/appraisal/" + appraisalId)).data;
    };

    async approveAppraisal(appraisal: Appraisal): Promise<Appraisal> {
        appraisal.approvalStatus = ApprovalStatus.Approved;
        return this.updateAppraisal(appraisal);
    }

    async rejectAppraisal(appraisal: Appraisal): Promise<Appraisal> {
        appraisal.approvalStatus = ApprovalStatus.Rejected;
        return this.updateAppraisal(appraisal);
    }

    async saveSelfAppraisal(
        appraisal: Appraisal,
        selfFeedback: FeedBackAppraisal
    ): Promise<Appraisal> {
        appraisal.selfAppraisal = selfFeedback;
        appraisal.selfAppraisal.inDraft = true;
        return this.updateAppraisal(appraisal);
    }

    async submitSelfAppraisal(
        appraisal: Appraisal,
        selfFeedback: FeedBackAppraisal
    ): Promise<Appraisal> {
        appraisal.selfAppraisal = selfFeedback;
        appraisal.selfAppraisal.submitted = true;
        appraisal.selfAppraisal.inDraft = false;
        return this.updateAppraisal(appraisal);
    }

    async saveLineMangerAppraisal(
        appraisal: Appraisal,
        lineMangerFeedback: FeedBackAppraisal
    ): Promise<Appraisal> {
        appraisal.lineMangerAppraisal = lineMangerFeedback;
        appraisal.lineMangerAppraisal.inDraft = true;
        return this.updateAppraisal(appraisal);
    }

    async submitLineMangerAppraisal(
        appraisal: Appraisal,
        lineMangerFeedback: FeedBackAppraisal
    ): Promise<Appraisal> {
        appraisal.lineMangerAppraisal = lineMangerFeedback;
        appraisal.lineMangerAppraisal.submitted = true;
        appraisal.lineMangerAppraisal.inDraft = false;
        return this.updateAppraisal(appraisal);
    }

    async updateAppraisal(appraisal: Appraisal): Promise<Appraisal> {
        return (await this.api.put<Appraisal>("/appraisal/" + appraisal.id, appraisal)).data;
    }

    async getPreviousAppraisal(): Promise<PreviousAppraisalsResponseDto> {
        return (
            await this.api.get<PreviousAppraisalsResponseDto>("/appraisal/my-previous-appraisal")
        ).data;
    }

    async getFeedBack(appraisalId: number): Promise<feedBackRequestResponce> {
        const url = "/appraisal/" + appraisalId + "/peer-feedback-by-employee/";
        return (await this.api.get(url)).data;
    }

    async submitPeerFeedBack(
        praise: string,
        critique: string,
        appraisalId: number
    ): Promise<string> {
        const feedback: PeerFeedBackDto = {
            appraisalId: appraisalId,
            praise: praise,
            critique: critique,
        };
        return (
            await this.api.put(
                "/appraisal/" + feedback.appraisalId + "/peer-feedback-by-employee/",
                feedback
            )
        ).data;
    }

    async nudgeAllActiveAppraisals() {
        await this.api.post("/appraisal/nudge/all");
    }

    async nudgeOneAppraisal(appraisalId: number) {
        await this.api.post("/appraisal/nudge/" + appraisalId);
    }

    async abortAppraisal(appraisalId: number): Promise<Appraisal> {
        return (await this.api.put<Appraisal>("/appraisal/" + appraisalId + "/Abort")).data;
    }
}
